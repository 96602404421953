import React from 'react'
import { Link } from 'react-router-dom';

export const Componente = () => {
    return (
        <div className="col-md-6 col-lg-3 col-sm-6 footer-col-4">
            <div className="widget widget_gallery gallery-grid-4">
                <h5 className="m-b30 text-white">Gallery</h5>
                <ul>
                    <li className="img-effect2"> <Link to='/'><img src="images/gallery/pic1.jpg" alt=""/></Link> </li>
                    <li className="img-effect2"> <Link to='/'><img src="images/gallery/pic2.jpg" alt=""/></Link> </li>
                    <li className="img-effect2"> <Link to='/'><img src="images/gallery/pic3.jpg" alt=""/></Link> </li>
                    <li className="img-effect2"> <Link to='/'><img src="images/gallery/pic4.jpg" alt=""/></Link> </li>
                    <li className="img-effect2"> <Link to='/'><img src="images/gallery/pic5.jpg" alt=""/></Link> </li>
                    <li className="img-effect2"> <Link to='/'><img src="images/gallery/pic6.jpg" alt=""/></Link> </li>
                    <li className="img-effect2"> <Link to='/'><img src="images/gallery/pic7.jpg" alt=""/></Link> </li>
                    <li className="img-effect2"> <Link to='/'><img src="images/gallery/pic8.jpg" alt=""/></Link> </li>
                    <li className="img-effect2"> <Link to='/'><img src="images/gallery/pic9.jpg" alt=""/></Link> </li>
                    <li className="img-effect2"> <Link to='/'><img src="images/gallery/pic1.jpg" alt=""/></Link> </li>
                    <li className="img-effect2"> <Link to='/'><img src="images/gallery/pic2.jpg" alt=""/></Link> </li>
                    <li className="img-effect2"> <Link to='/'><img src="images/gallery/pic3.jpg" alt=""/></Link> </li>
                </ul>
            </div>
        </div>
    )
}

export default Componente;