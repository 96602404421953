import React, {useState,useEffect} from 'react'
import axios from 'axios'
import Init from '../../common/Init'

const Form = () => {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');

    const mapSetState = {
        'name': setName,
        'email': setEmail,
        'phone': setPhone,
        'message': setMessage
    }

    useEffect(() => {
        Init.initForm()
    },[])

    const handleChange = e => {
        mapSetState[e.target.name](e.target.value)
    }

    const resetField = () => {
        Object.keys(mapSetState).forEach( key => {
            mapSetState[key]('')
        })
    }

    const handleSubmit = e => {
        e.preventDefault();
        if(name === '' || email === '' || phone === '' || message === '') {
            alert('fills required fields...')
            return;
        }
        axios({
            method: "post",
            url: `${process.env.REACT_APP_API}`,
            data: { name, email, msg: message, phone},
            crossDomain: true,
            mode: 'cors',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'JSON',
            }
        })
        .then(result => {
            let data = result.data
            alert(data.message)
            resetField();
        })
        .catch( error => console.log(error.message) )
    }

    return (
        <div className="m-b30 formQuote">
            <div className="request-form dezPlaceAni">
                <div className="request-form-header">
                    <i className="flaticon-message"></i>
                    <p>Don’t Hesitate To Ask</p>
                    <h2>Request A Quote</h2>
                </div>
                <form>
                    <div className="form-group">
                        <div className="input-group">
                            <label>Your Name</label>
                            <input onChange={handleChange} value={name} name="name" type="text" required className="form-control" placeholder=""/>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="input-group">
                            <label>Email</label>
                            <input onChange={handleChange} value={email} name="email" type="text" required className="form-control" placeholder=""/>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="input-group">
                            <label>Phone</label>
                            <input onChange={handleChange} value={phone} name="phone" type="text" required className="form-control" placeholder=""/>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="input-group">
                            <label>Quote Details</label>
                            <textarea onChange={handleChange} value={message} name="message" className="form-control" required></textarea>
                        </div>
                    </div>
                    <div className="form-group">
                        <button className="site-button btnhover19 button-md btn-block" onClick={ handleSubmit }> Submit Inquiry</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Form
