import React from 'react'
import TitlePage from '../../../common/TitlePage'
import ImageRadius from '../../../common/ImageRadius'

export const Content = () => {
    let propForEffects = { 'data-wow-duration': '2s', 'data-wow-delay': '0.3s' }
    return (
        <div className="container m-b60 customPage">
            <div className="section-full content-inner-1 about-bx">
                {/* // ------------------------------ROW----------------------------------- */}
                <div className="row mb-4">
                    {/* // ------------------------------COL 1----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                        <ImageRadius src="images/services/electrical_outlet_1.jpg"/>
                    </div>
                    {/* // ------------------------------COL 2----------------------------------- */}
                    <div className= "col-sm-12 col-md-6 col-lg-6 wow fadeInRight" { ...propForEffects }>
                        <div className="align-self-center">
                            <TitlePage title={ "Electrical Outlet Instalation" } />
                            <p>
                            Our skilled contractors provide professional electrical outlet installation for businesses of all sizes. Installing outlets requires training and experience. To ensure safety as well as code compliance, business need to hire a licensed professional for power outlet installation. Experienced electricians know how to properly ground wiring to prevent fires and other power hazards. If you need an electrician to install outlets at your business, our contractors are available on your schedule. We complete outlet projects as quickly as possible on your timeline.
                            </p>
                            <TitlePage title={ "Upgrading Power Outlets" } />
                            <p>
                            Our contractors work on outlet upgrades and replacement for businesses of all sizes, so no job is too big or small.  Fixing faulty power outlets work is essential for safety, therefore we also test and troubleshoot outlets.
                            </p>
                        </div>
                    </div>
                </div>
                {/* // ------------------------------ROW----------------------------------- */}
                <div className="row mb-4">
                    {/* // ------------------------------COL 1----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                        <div className="align-self-center">
                            <TitlePage title={ "Installing Electrical Outlets" } />
                            <p>
                            We provide skilled electrical contractors in Houston for all types of outlet installation projects, such as GFCI outlets, outdoor power outlets, ceiling outlets and more. We also provide solutions for energy efficiency, telephone installation, data networks, standby generators and more. We are the expert contractors trusted businesses for fast and efficient installations and repairs. Call us at (919) 387-2220	, or contact us online for professional electrical outlet installation.
                            </p>
                        </div>
                    </div>
                    {/* // ------------------------------COL 2----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInRight m-b30" { ...propForEffects }>
                        <ImageRadius src="images/services/electrical_outlet_2.jpg"/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Content;