import React from 'react'
import TitlePage from '../../common/TitlePage'
import ImageRadius from '../../common/ImageRadius'

export const Content = () => {
    let propForEffects = { 'data-wow-duration': '2s', 'data-wow-delay': '0.3s' }
    return (
        <div className="container m-b60 customPage">
            <div className="section-full content-inner-1 about-bx">
                {/* // ------------------------------ROW----------------------------------- */}
                <div className="row mb-4">
                    {/* // ------------------------------COL 1----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                        <ImageRadius src="images/localServices/local_electricians.jpg"/>
                    </div>
                    {/* // ------------------------------COL 2----------------------------------- */}
                    <div className= "col-sm-12 col-md-6 col-lg-6 wow fadeInRight" { ...propForEffects }>
                        <div className="align-self-center">
                            <TitlePage title={ "Lighting Retrofit Solutions" } />
                            <p>
                                Our reliable local electricians in North Carolina provide services for all types of commercial and industrial businesses.
                            </p>
                            <p>
                                Additional service areas: Asheville, Charlotte, Durham, Greensboro, Greenville, Raleigh, Wilmington, Winston-Salem, Cary and Fayetteville.
                            </p>
                            <p>
                                If you are looking for local electricians to complete a unique industrial or commercial electric project, we provide custom solutions for all types of businesses. Our pros also perform energy saving inspections and audits to help you reduce your utility costs. If you need an electrical contractor for emergency power restoration or other electric services, give us a call us at (919) 387-2220	. You can also make an appointment with our convenient online contact form.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Content;