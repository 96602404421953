import React from 'react'
import TitlePage from '../../../common/TitlePage'
import ImageRadius from '../../../common/ImageRadius'

export const Content = () => {
    let propForEffects = { 'data-wow-duration': '2s', 'data-wow-delay': '0.3s' }
    return (
        <div className="container m-b60 customPage">
            <div className="section-full content-inner-1 about-bx">
                {/* // ------------------------------ROW----------------------------------- */}
                <div className="row mb-4">
                    {/* // ------------------------------COL 1----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                        <ImageRadius src="images/services/plc_1.jpg"/>
                    </div>
                    {/* // ------------------------------COL 2----------------------------------- */}
                    <div className= "col-sm-12 col-md-6 col-lg-6 wow fadeInRight" { ...propForEffects }>
                        <div className="align-self-center">
                            <TitlePage title={ "Programmable Logic Controllers" } />
                            <p>
                            Programmable Logic Controllers are a valuable tool for the automation of electromechanical processes. Our industrial electricians work on Programmable Logic Control (PLC) centers to ensure effective process automation for a range businesses.
                            </p>
                            <TitlePage title={ "PLC Services" } />
                            <p>
                            Our industrial contractors design and build automation controls for new processes, machinery and system modifications. In addition, we also install, configure and program PLC equipment to enable integration and communication with other systems and equipment controllers. Our contractors work on conveyor control systems, batching systems, generating stations and control panels. We also perform upgrades and retrofits.
                            </p>
                        </div>
                    </div>
                </div>
                {/* // ------------------------------ROW----------------------------------- */}
                <div className="row mb-4">
                    {/* // ------------------------------COL 1----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                        <div className="align-self-center">
                            <TitlePage title={ "PLC Functionality" } />
                            <p>
                            Our expert industrial contractors understand the complete functionality of Programmable Logic Controls. Our technicians are experts in process control, motion control, sequential relay control, distributed control systems, as well as networking. Our electricians program controllers in a variety of ways, such as relay-derived ladder logic and various programming languages.
                            </p>
                            <TitlePage title={ "Qualified Electricians for Complete Services" } />
                            <p>
                            You can trust our contractors in Houston with your PLC work. Our industrial electricians also work on emergency power restoration, energy efficiency solutions, retrofitting lights, harmonic mitigation, standby generators, power circuits, dedicated circuits, service upgrades, carousel and conveyor wiring, specialty voltages and more.
                            </p>
                        </div>
                    </div>
                    {/* // ------------------------------COL 2----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInRight" { ...propForEffects }>
                        <ImageRadius src="images/services/plc_2.jpg"/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Content;