import React, { useEffect } from 'react'
import Init from '../common/Init'
import { carrouselCircles } from '../common/Constants'
import ScrollTopFunction from '../common/ScrollTopFunction'
import { Link } from 'react-router-dom'

export const Component = () => {

	useEffect(() => {
		Init.initDzCarrousel();
	}, [])

	return (
		<div className="section-full bg-gray content-inner about-carousel-ser">
			<div className="container">
				<div className="section-head text-center">
					<h2 className="title">
						Commercial & Industrial
						<br/><span className="text-primary">Electrical Services</span>
					</h2>
					<p>Techsource Workforce Solutions is a full-service electrical contractor providing construction services for commercial & industrial businesses.</p>
				</div>
				<div className="about-ser-carousel owl-carousel owl-theme owl-btn-center-lr owl-dots-primary-full owl-btn-3 wow fadeIn" data-wow-duration="2s" data-wow-delay="0.2s">
					{
						carrouselCircles.map((item, index) => {
							return(
								<div key={ index } className="item">
									<div className="dlab-box service-media-bx">
										<div className="dlab-media">
											<Link to={ item.path } onClick={ () => ScrollTopFunction() }>
												<img src={ item.img } alt=""/>
											</Link>
										</div>
										<div className="dlab-info text-center">
											<h2 className="dlab-title">
												<Link to={ item.path } onClick={ () => ScrollTopFunction() }>{ item.title }</Link>
											</h2>
											<p>{ item.content }</p>
											<Link to={ item.path } className="site-button btnhover13" onClick={ () => ScrollTopFunction() }>Read More</Link>
										</div>
									</div>
								</div>
							)
						})
					}
				</div>
			</div>
		</div>
	)
}

export default Component;