import React from 'react'
import TitlePage from '../../../../common/TitlePage'
import ImageRadius from '../../../../common/ImageRadius'

export const Content = () => {
    let propForEffects = { 'data-wow-duration': '2s', 'data-wow-delay': '0.3s' }

    return (
        <div className="container m-b60 customPage">
            <div className="section-full content-inner-1 about-bx">
                {/* // ------------------------------ROW----------------------------------- */}
                <div className="row mb-4">
                    {/* // ------------------------------COL 1----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                        <ImageRadius src="images/services/instrumentation_and_startup.jpg"/>
                    </div>
                    {/* // ------------------------------COL 2----------------------------------- */}
                    <div className= "col-sm-12 col-md-6 col-lg-6 wow fadeInRight" { ...propForEffects }>
                        <div className="align-self-center">
                            <TitlePage title={ "Instrumentation And Start Up" } />
                            <p>
                                From distribution systems and final circuits, through to complex automation and instrumentation work, we have the capability to deliver scalable, quality installation, electrical testing and commissioning services to the highest standards.
                            </p>
                            <p>
                                With a proven ability to deliver on time and on budget our clients know they can count on us to get the job done. They also know that, alongside our design and consultancy capability, our installation service provides not only the complete package, but a highly cost effective one too.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Content;