import React from 'react'
import TitlePage from '../../../common/TitlePage'
import ImageRadius from '../../../common/ImageRadius'

export const Content = () => {
    let propForEffects = { 'data-wow-duration': '2s', 'data-wow-delay': '0.3s' }
    return (
        <div className="container m-b60 customPage">
            <div className="section-full content-inner-1 about-bx">
                {/* // ------------------------------ROW----------------------------------- */}
                <div className="row mb-4">
                    {/* // ------------------------------COL 1----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                        <ImageRadius src="images/services/gfci_outlet_1.jpg"/>
                    </div>
                    {/* // ------------------------------COL 2----------------------------------- */}
                    <div className= "col-sm-12 col-md-6 col-lg-6 wow fadeInRight" { ...propForEffects }>
                        <div className="align-self-center">
                            <TitlePage title={ "Installing GFCI Outlets" } />
                            <p>
                            Ground fault circuit interrupter or GFCI outlet installation is an important safety measure for businesses, since it provides protection from electrical hazards including fatal shock and electrocution. GFCI outlets also help to prevent damage to equipment and machinery. Monitoring energy flows, a GFCI stops electricity from running to electronics if a ground fault is detected.
                            </p>
                            <TitlePage title={ "Wiring a GFCI Outlet" } />
                            <p>
                            Providing GFCI outlet installation for all types of businesses, our commercial electric contractors are experienced in installing outlets safely. We also wire and install other types of power outlets, including tamper resistant outlets and child proof outlets. Our electricians are available for working on outlets on your schedule.
                            </p>
                        </div>
                    </div>
                </div>
                {/* // ------------------------------ROW----------------------------------- */}
                <div className="row mb-4">
                    {/* // ------------------------------COL 1----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                        <div className="align-self-center">
                            <TitlePage title={ "Replacing Outlets" } />
                            <p>
                            A range of businesses hire us to replace power outlets with GFCI outlets. GFCI outlet installation is required for code compliance in areas that are in proximity to water, including bathrooms, kitchens, garages and outdoor areas. However GFCI outlets can also be installed in every room.
                            </p>
                            <TitlePage title={ "Reliable GFCI Outlet Installation" } />
                            <p>
                            Our commercial electric contractors provide the most reliable GFCI outlet installation and complete electrical services for a range of industrial and commercial businesses. We provide 24 hour emergency electricians for outlets and other electric repairs. If you need a contractor for GFCI outlets, call us at (919) 387-2220	 or contact us online.
                            </p>
                        </div>
                    </div>
                    {/* // ------------------------------COL 2----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInRight m-b30" { ...propForEffects }>
                        <ImageRadius src="images/services/gfci_outlet_2.jpg"/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Content;