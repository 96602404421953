import React from 'react'
import TitlePage from '../../../common/TitlePage'
import ImageRadius from '../../../common/ImageRadius'


export const Content = () => {
  let propForEffects = { 'data-wow-duration': '2s', 'data-wow-delay': '0.3s' }
  return (
    <div className="container m-b60 customPage">
      <div className="section-full content-inner-1 about-bx">
        {/* // ------------------------------ROW----------------------------------- */}
        <div className="row mb-4">
          {/* // ------------------------------COL 1----------------------------------- */}
          <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
            <ImageRadius src="images/services/data_wiring_1.jpg"/>
          </div>
          {/* // ------------------------------COL 2----------------------------------- */}
          <div className= "col-sm-12 col-md-6 col-lg-6 wow fadeInRight" { ...propForEffects }>
            <div className="align-self-center">
              <TitlePage title={ "Data Networking and Cabling Services" } />
              <p>
              Our professional electricians provide energy efficient lighting retrofit solutions for all types of businesses. A retrofit involves modifications to your existing fixtures, lamps and systems. With new technologies that require less power than older lamps, retrofitting lights helps conserve energy. A lighting retrofit can produce significant savings on electricity costs.
              </p>
              <TitlePage title={ "Professional Data Networking Installation" } />
              <p>
              We handle all of aspects networking, including structured cabling design and implementation, data line installations, electrical wiring, Cat 6 installation, as well as fiber optic installation and termination. Providing data networking installation services for businesses of all sizes, we also specialize in custom business solutions.
              </p>
            </div>
          </div>
        </div>
        {/* // ------------------------------ROW----------------------------------- */}
        <div className="row mb-4">
          {/* // ------------------------------COL 1----------------------------------- */}
          <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
            <div className="align-self-center">
              <TitlePage title={ "Structured Cabling" } />
              <p>
              Structured cabling is a solution for data networking that includes data, telephone, audio, video, security and other electronics in a building. A structured networking distribution panel distributes signals through direct cable lines to multiple rooms in a building. These direct lines provide the strongest connection possible to all of your electronic devices and equipment. Our electricians install structured cabling systems with pre-configured and custom distribution panels to integrate the components of your system.
              </p>
              <p>
              Our electric contractors have extensive experience in data installation and electrical wiring projects. In addition to network cabling services, we also offer solutions for voice communications systems, security systems, access control, fire alarms, and more. Whatever your unique needs may be, you can count on our technicians to deliver the best results. For quality data networking services, call us at (919) 387-2220	 or contact us online.
              </p>
            </div>
          </div>
          {/* // ------------------------------COL 2----------------------------------- */}
          <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInRight" { ...propForEffects }>
            <ImageRadius src="images/services/data_wiring_2.jpg"/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Content;