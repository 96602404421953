import React from 'react'

const ImageRadius = ({ src }) => {
    return (
        <div className='dlab-media'>
            <img className='alignright radius-sm' src={ src } width="300" alt=""/>
        </div>
    )
}

export default ImageRadius
