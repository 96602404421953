import React from 'react'
import TitlePage from '../../common/TitlePage'
import ImageRadius from '../../common/ImageRadius'

export const Content = () => {
    let propForEffects = { 'data-wow-duration': '2s', 'data-wow-delay': '0.3s' }
    return (
        <div className="container m-b60 customPage">
            <div className="section-full content-inner-1 about-bx">
                {/* // ------------------------------ROW----------------------------------- */}
                <div className="row mb-4">
                    {/* // ------------------------------COL 1----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                        <ImageRadius src="images/consulting/consulting.jpg"/>
                    </div>
                    {/* // ------------------------------COL 2----------------------------------- */}
                    <div className= "col-sm-12 col-md-6 col-lg-6 wow fadeInRight" { ...propForEffects }>
                        <div className="align-self-center">
                            <TitlePage title={ "Lighting Retrofit Solutions" } />
                            <p>
                                We offer electrical installation preliminary budgets and breakouts, load analysis, and one-lines, and liaise with utilities to assist in achieving your goals. All you have to do is allow us to get the obstacles out of the way, and prep your project for bids. We will even bid your project if requested.
                            </p>
                            <p>
                                We fill the gap that exists for customers that have not or are not yet ready to seek out an engineer, yet want advice and consent on the actual issues facing an electrical construction or installation project in a manufacturing or processing facility environment. Even those that have hired an engineer find added value in using TWS Consulting. We know construction in real time and in real life, not just on paper. Items of significant cost can be evaluated and solved before the construction starts. Saving money and solving the problem, before it becomes an issue.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Content;