import React from 'react'

const ListBox = ({ list, color = '' }) => {
    return (
        <div className="frame-sr-list-area">
            <div className="row">
                <div className="col-lg-12">
                    <ul className={ "list-star " + color + " red list-box customPaddinglist" }>
                    {
                        list.map((item,key) => {
                            return(
                                <li className="text-justify" key={ key }>{ item.name }</li>
                            )
                        })
                    }
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default ListBox
