import React from 'react'
import Slide from './Slide';
import { sliders } from '../common/Constants'

const SliderMainVideo = () => {
    return (
        <div className="rev-slider-full">
			<div id="rev_slider_14_1_wrapper" className="rev_slider_wrapper fullscreen-container"  data-alias="gravitydesign1" data-source="gallery" style={{ background: '#1d2931', padding:'0px' }}>
                <div id="rev_slider_14_1" className="rev_slider fullscreenbanner" data-version="5.4.1" style={{ display:'none' }}>
                    <ul>
                        {
                            sliders.filter(item => item.display).map((slide,i) => {
                                return(
                                    <li key={ i }
                                    data-index={ 'rs-' + i }
                                    data-transition='fade'
                                    data-slotamount='default'
                                    data-hideafterloop='0'
                                    data-hideslideonmobile="off"
                                    data-easein='default'
                                    data-easeout='default'
                                    data-masterspeed='300'
                                    data-rotate='0'
                                    data-saveperformance='off'
                                    data-title='Slide'
                                    data-param1={ slide.params[0] }
                                    data-param2={ slide.params[1] }
                                    data-param3={ slide.params[2] }
                                    data-param4={ slide.params[3] }
                                    data-param5={ slide.params[4] }
                                    data-param6={ slide.params[5] }
                                    data-param7={ slide.params[6] }
                                    data-param8={ slide.params[7] }
                                    data-param9={ slide.params[8] }
                                    data-param10={ slide.params[9] }
                                    data-description={ slide.params.description }>
                                        <img src={ 'images/slider/dummy2.png' } data-bgcolor="#1d2931" style={{ background: '#1d2931' }} alt="" data-bgposition="center center" data-bgfit="cover" data-bgrepeat="no-repeat" data-bgparallax="off" className="rev-slidebg" data-no-retina></img>
                                        <Slide slide={ slide } ></Slide>
                                    </li>
                                )
                            })
                        }
                    </ul>
                    <div className='tp-bannertimer tp-bottom' style={{ visibility: 'hidden !important' }}></div>
                </div>
            </div>
        </div>
    )
}

export default SliderMainVideo
