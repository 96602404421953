import React from 'react'
import { Link } from 'react-router-dom'
import ScrollTopFunction from '../common/ScrollTopFunction'

const Company = props => {
  const menuFooter = [
    {
      path: '/about',
      name: 'About Us'
    }, {
      path: '/services',
      name: 'Services'
    }, {
      path: '/comercial-electrical',
      name: 'Commercial'
    }, {
      path: '/industrial',
      name: 'Industrial'
    }, {
      path: '/electrical-consulting',
      name: 'Consulting'
    }
  ]
  return (
    <div className='d-none d-sm-none d-md-block col-md-4 col-5 col-xl-2 col-lg-2 col-sm-6 footer-col-4' data-wow-delay='0.2s'>
      <div className="widget widget_services border-0">
        <h5 className='m-b30 text-white'>Company</h5>
        <ul className='list-1'>
          {
            menuFooter.map((item,key) => {
              return(
                <li key={key}><Link onClick={ ScrollTopFunction } style={{ color: '#b0b0b0' }} to={item.path} >{item.name}</Link></li>
              )
            })
          }
        </ul>
      </div>
    </div>
  )
}

export default Company
