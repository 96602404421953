import React from 'react'
import TitlePage from '../../../common/TitlePage'
import ImageRadius from '../../../common/ImageRadius'
import SectionYellow from '../../../common/SectionYellow'

export const Content = () => {
    const propForEffects = { 'data-wow-duration': '2s', 'data-wow-delay': '0.3s' }
    const section = {
        title: 'Our Services Include',
        list: [
            { content: 'Mechanical Construction & Renovation', icon: 'flaticon-factory' },
            { content: 'Mechanical System Maintenance', icon: 'flaticon-factory-1' },
            { content: '24/7 Emergency Service', icon: 'flaticon-factory' }
        ]
    }
    return (
        <div className="content-block">
            <div className="container m-b60 customPage">
                <div className="section-full content-inner-1 about-bx">
                    {/* // ------------------------------ROW----------------------------------- */}
                    <div className="row mb-4">
                        {/* // ------------------------------COL 1----------------------------------- */}
                        <div className= "col-sm-12 col-md-6 col-lg-6 wow fadeInLeft" { ...propForEffects }>
                            <ImageRadius src="images/services/mechanical_services.jpg"/>
                        </div>
                        {/* // ------------------------------COL 2----------------------------------- */}
                        <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInRight m-b30" { ...propForEffects }>
                        <TitlePage title={ "Equipment Upgrades" } />
                            <p>
                            Plant and facility managers across the country, including in Asheville, Charlotte, Durham, Greensboro, Greenville, Raleigh, Wilmington, Winston-Salem, Cary and Fayetteville, rely on turnkey mechanical solutions from TWS, Inc. to meet their operational goals. Whether you are constructing a new commercial facility or relocating it, renovating or expanding your industrial operations, you can count on our seamless design and installation services to help enhance equipment output and lifespan. Extensive mechanical experience spanning diverse industries enables us to complete your most complex installs on time and within budget. Discuss your requirements with us.
                            </p>
                        </div>
                    </div>
                    {/* // ------------------------------ROW----------------------------------- */}
                    <div className="row mb-4">
                        {/* // ------------------------------COL 1----------------------------------- */}
                        <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                            <div className="align-self-center">
                                <TitlePage title={ "New Equipment Installations" } />
                                <p>
                                    With expertise in new construction, build outs and renovations, the dedicated team at TWS, Inc. is able to efficiently meet all of your needs. Extensive capabilities enable us to provide quick turnaround on customer requests from around the country.
                                </p>
                                <p>
                                    As a customer of TWS, you will benefit from lower operational costs, increased equipment reliability, and enhanced productivity. Our mechanical team is licensed, insured and stays on the cutting-edge of technology and safety codes to provide service that delivers both quality and value. We understand the importance of maintaining reliable performance and offer expertise that covers a wide range of mechanical systems.
                                </p>
                            </div>
                        </div>
                        {/* // ------------------------------COL 2----------------------------------- */}
                        <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInRight" { ...propForEffects }>
                        <ImageRadius src="images/services/mechanical_services_2.jpg"/>
                        </div>
                    </div>
                </div>
            </div>
            {/* // ------------------------------ROW YELLOW SECTION----------------------------------- */}
            <div className="section-full p-b20 box-about-list">
                    <div className="row spno">
                        {/* // ------------------------------COL 1----------------------------------- */}
                        <div className="col-lg-6 col-md-12 wow fadeInLeft m-b30" { ...propForEffects }>
                            <img src="images/services/mechanical_services_3.jpg" alt="" className="img-cover"/>
                        </div>
                        {/* // ------------------------------COL 2----------------------------------- */}
                        <div className="col-lg-6 col-md-12 bg-primary wow fadeInRight m-b30 " { ...propForEffects }>
                        <SectionYellow { ...section } />
                        </div>
                    </div>
                </div>
        </div>
    )
}

export default Content;