import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import changeNavSelected from '../../../redux/actions/changeNavSelected'
import PageBanner from '../../common/PageBanner'
import Content from './Content'

const Consulting = props => {

    useEffect(() => {
        props.changeNavSelected(5)
    }, [])

    return (
        <div className='bg-white'>
            <PageBanner title='Electrical Consulting' img={{ name: 'commonBanner', type: 'jpg' }}></PageBanner>
            <Content></Content>
        </div>
    )
}

const mapStateToProps = state => { return state }
const mapDispatchToProps = { changeNavSelected }

export default connect(mapStateToProps,mapDispatchToProps)(Consulting)