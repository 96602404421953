import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import changeNavSelected from '../../../redux/actions/changeNavSelected'
import DzetItem from './DzetItem'
import Form from './Form';


export const Content = () => {
    let propForEffects = { 'data-wow-duration': '2s', 'data-wow-delay': '0.3s' }
    return(
        <div className="container">
            <div className="row mt-4">
                {/* // ------------------------------COL 1----------------------------------- */}
                <div className= "col-sm-12 col-md-8 col-lg-8 m-b20 wow fadeInLeft" { ...propForEffects }>
                    <Form/>
                </div>
                {/* // ------------------------------COL 2----------------------------------- */}
                <div className="col-sm-12 col-md-4 col-lg-4 m-b20 wow fadeInRight" { ...propForEffects }>
                    <DzetItem icon="ti-location-pin" title="Address" content="1031 E Mountain St. Kernersville, NC  27284"/>
                    <DzetItem icon="ti-email" title="Email" content="bmendiola@svecnc.com"/>
                    <DzetItem icon="ti-mobile" title ="Phone" content="(919) 387-2220	"/>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => { return state }
const mapDispatchToProps = { changeNavSelected }

export default connect(mapStateToProps,mapDispatchToProps)(Content)