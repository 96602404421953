import React from 'react'
import TitlePage from '../../../common/TitlePage'
import ImageRadius from '../../../common/ImageRadius'

export const Content = () => {
    let propForEffects = { 'data-wow-duration': '2s', 'data-wow-delay': '0.3s' }
    return (
        <div className="container m-b60 customPage">
            <div className="section-full content-inner-1 about-bx">
                {/* // ------------------------------ROW----------------------------------- */}
                <div className="row mb-4">
                    {/* // ------------------------------COL 1----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                        <ImageRadius src="images/services/standby_generator_1.jpg"/>
                    </div>
                    {/* // ------------------------------COL 2----------------------------------- */}
                    <div className= "col-sm-12 col-md-6 col-lg-6 wow fadeInRight" { ...propForEffects }>
                        <div className="align-self-center">
                            <TitlePage title={ "Installing Standby Generators" } />
                            <p>
                            Standby generator installation is a necessity for industrial businesses. Due to the unpredictability of storms and disasters, a back-up power source is important for businesses. Enabling emergency power restoration during outages, overload shutdowns and system failures, standby generators are critical to preventing downtime and safeguarding perishable assets. In the event of any unforeseen circumstances, standby generator installation enables you to restore power to your facility in minutes.
                            </p>
                            <TitlePage title={ "Generator Power Requirements" } />
                            <p>
                            Selecting the right standby generators for an industrial business involves an understanding of power calculations. Our technicians evaluate your power usage to help you determine the type and number of generators that you need. Before starting your installation project, our electricians will determine your total energy requirements.
                            </p>
                        </div>
                    </div>
                </div>
                {/* // ------------------------------ROW----------------------------------- */}
                <div className="row mb-4">
                    {/* // ------------------------------COL 1----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                        <div className="align-self-center">
                            <TitlePage title={ "Standby Generator Installation" } />
                            <p>
                            Providing generator installation of up to 2 megawatts, we ensure the efficiency and stability that your business requires. Our industrial electric contractors provide timely and cost-effective generator installation services. In addition, our electricians also look for opportunities to reduce your generator power needs, by excluding nonessential items from your generator circuit.
                            </p>
                            <TitlePage title={ "Complete Standby Generator Services" } />
                            <p>
                            Our experienced electricians understand the critical nature of industrial power reliability. Therefore our electricians help you protect the operation of your business with emergency generator repairs and troubleshooting. Our contractors also perform inspections and maintenance of generators and all types of machinery and equipment.
                            </p>
                        </div>
                    </div>
                    {/* // ------------------------------COL 2----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInRight" { ...propForEffects }>
                        <ImageRadius src="images/services/standby_generator_2.jpg"/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Content;