import React from 'react'
import TitlePage from '../../../common/TitlePage'
import ImageRadius from '../../../common/ImageRadius'

export const Content = () => {
    let propForEffects = { 'data-wow-duration': '2s', 'data-wow-delay': '0.3s' }
    return (
        <div className="container m-b60 customPage">
            <div className="section-full content-inner-1 about-bx">
                {/* // ------------------------------ROW----------------------------------- */}
                <div className="row mb-4">
                    {/* // ------------------------------COL 1----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                        <ImageRadius src="images/services/electrical_wiring_1.jpg"/>
                    </div>
                    {/* // ------------------------------COL 2----------------------------------- */}
                    <div className= "col-sm-12 col-md-6 col-lg-6 wow fadeInRight" { ...propForEffects }>
                        <div className="align-self-center">
                            <TitlePage title={ "Electrical Wiring" } />
                            <p>
                            Providing professional electrical wiring services, we’re a leading Houston electrician for businesses of all sizes. Our experienced technicians have experience with all types of power systems and equipment.
                            </p>
                            <TitlePage title={ "All types of Electrical Wiring" } />
                            <p>
                            Our skilled electric contractors install power systems, outlets, illumination systems, signage, and all types of electronics and equipment. In addition, we install phone jacks, telephone networking, data cabling and more. If you need a professional to install security and alarm systems, entertainment and surround sound audio systems, or unique custom projects, contact us. We also provide contractors for all types of industrial projects.
                            </p>
                        </div>
                    </div>
                </div>
                {/* // ------------------------------ROW----------------------------------- */}
                <div className="row mb-4">
                    {/* // ------------------------------COL 1----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                        <div className="align-self-center">
                            <TitlePage title={ "Wiring Inspections & More" } />
                            <p>
                            We are a licensed electrician for inspections. To pass safety inspections, installations must be code compliant. Additionally, professional inspections are also important for optimal energy efficiency. Our technicians are skilled at diagnosing problems with power systems. After the inspection, we will provide a reliable estimate for repairing or replacing wires.
                            </p>
                            {/* <TitlePage title={ "Dependable Service When You Need It" } /> */}
                            <p>
                            Since we are a top contractor for electrical wiring installation and repairs, you can count on us to get the job done right. We also provide contractors for emergency power restoration, harmonic mitigation, equipment and machinery repairs, networks and data lines, as well as a full range of power solutions.
                            </p>
                        </div>
                    </div>
                    {/* // ------------------------------COL 2----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInRight m-b30" { ...propForEffects }>
                        <ImageRadius src="images/services/electrical_wiring_2.jpg"/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Content;