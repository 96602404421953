import React from 'react'
import { Link } from 'react-router-dom'
import ScrollTopFunction from '../common/ScrollTopFunction'

const GallerySection = () => {
    return (
        <div className='section-full'>
            <div className='row m-lr0'>
                <div className='col-xl-6 col-lg-12 p-lr0 d-flex dis-tbl latest-project-info bg-primary wow fadeInLeft' data-wow-duration='2s' data-wow-delay='0.3s'>
                    <div className='text-white align-self-center'>
                        <h2>Our Services</h2>
                        <p><strong>Your Skilled Trades Staffing Advantage</strong> - You can’t afford to leave your project in the hands of
                            unqualified workers - project delays and shoddy work can cripple your business. We are specialized
                            in the construction staffing industry, providing America’s best skilled tradespeople for medium and
                            large contractors in the commercial and industrial space. Techsource Workforce Solutions
                            offers you the best quality in Electrical commercial and industrial services, we hired the best
                            team of technicians in the USA providing you quality as our main goal. We offer service in corporate
                            offices, interior renovations, parking, Panel replacement, underground wiring, electrical wiring, lighting replacement, plugs and more.</p>
                        <Link to='/capabilities' onClick={ () => ScrollTopFunction() } className='site-button btnhover8 white'>View Services</Link>
                    </div>
                </div>
                <div className='col-xl-6 col-lg-12 p-lr0 wow fadeInRight' data-wow-duration='2s' data-wow-delay='0.3s'>
                    <div className='row m-a0 lightgallery'>
                        <div className='col-lg-12 col-md-12 col-sm-12 p-lr0'>
                            <div className='dlab-box overlay-gallery-bx1 lightgallery'>
                                <div className='dlab-thum dlab-img-overlay1 primary'>
                                    <Link to='/'> <img className='img-cover' src='images/gallery/our_services.jpg'  alt=''/> </Link>
                                    <div className='overlay-bx'>
                                        <div className='overlay-icon'>
                                            <span data-exthumbimage='images/gallery/our_services.jpg' data-src='images/gallery/our_services.jpg' className='check-km' title=''>
                                                <i className='fa fa-search icon-bx-xs'></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GallerySection
