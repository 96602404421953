import React from 'react';
import LocationMap from './LocationMap';

const MapZone = props => {
  return (
    <div className="col-lg-6 m-b30 d-flex">
      <LocationMap style={{position:'initial'}}></LocationMap>
     </div>
  )
}

export default MapZone
