import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import ScrollTopFunction from '../../components/common/ScrollTopFunction'
import changeNavSelected from '../../redux/actions/changeNavSelected'
import changeIndexAccordion from '../../redux/actions/changeIndexAccordion'
import Logo from './Logo'

const MainNav = props => {
    var [ isDesktop, setIsDesk ] = useState(window.matchMedia("(min-width: 992px)").matches)
    var [ options, setOptions ] = useState([])
    let { menuOptions, menuOptionsMobile } = props.state
    let classDisplayMobile = 'header-nav navbar-collapse collapse justify-content-end'

    useEffect(() => {
        if(isDesktop) {
            setOptions(menuOptions)
        } else {
            setOptions(menuOptions)
            // setOptions(menuOptionsMobile)
        }
    }, [isDesktop])

    const changeStatus = (keyfather,key) => {
        props.changeNavSelected(keyfather)
        props.changeIndexAccordion(key)
        ScrollTopFunction()
        if(window.matchMedia("(min-width: 992px)").matches) {
            window.$('.navbar-nav > li > a').next('.sub-menu').slideUp()
        }
    }

    window.$(window).resize(() => {
        if(window.matchMedia("(min-width: 992px)").matches) {
            setIsDesk(true)
        } else {
            setIsDesk(false)
        }
    })

    const CollapseSubMenu = () => {
        window.$(".displ").removeClass('open')
    }

    window.$(".displ").hover(function(){
        if(window.matchMedia("(min-width: 992px)").matches) {
            window.$('.navbar-nav > li > a').next('.sub-menu').slideDown()
        }
    });

    const getOption = (status, key, keyFather, menuOption) => {
        if (menuOption.displayItems && menuOption.isSubMenu) {
            return(
                <li className={ status } key={ key } onClick={ e => { e.stopPropagation(); changeStatus(keyFather,0) } }>
                    <Link to={ menuOption.path }> { menuOption.name } <i className='fa fa-angle-right' style={{ marginTop: menuOption.name.length > 21 ? '-12px' : '4px' }}></i> </Link>
                    <ul className='sub-menu tab-content'> {
                        menuOption.items.map((item,i) => {
                            if(item.display) {
                                return(
                                    <li key={ i } onClick={ e => { e.stopPropagation(); changeStatus(keyFather,item.keyAccordion); if(!window.matchMedia("(min-width: 992px)").matches){  window.$( ".navbar-toggler" ).trigger( "click" ); CollapseSubMenu(); } } }>
                                        <Link to={ item.path }> { item.name } </Link>
                                    </li>
                                )
                            }
                        })
                    } </ul>
                </li>
            )
        } else if(menuOption.displayItems) {
            return(
                <li key={ key } className={ 'displ ' + status } onClick={ e => { e.stopPropagation(); changeStatus(keyFather,0) } }>
                    <Link to={ menuOption.path } className='menu-option'> { menuOption.name } <i className='fa fa-chevron-down'></i> </Link>
                    <ul className='sub-menu'> {
                        menuOption.items.map((item,i) => {
                            if(item.display) {
                                return( getOption('',i,keyFather,item) )
                            }
                        })
                    } </ul>
                </li>
            )
        }
        return(
            <li className={ status } key={ key } onClick={ e => { e.stopPropagation(); changeStatus(keyFather, 0); if(!window.matchMedia("(min-width: 992px)").matches){  window.$( ".navbar-toggler" ).trigger( "click" ); } } }>
                <Link to={ menuOption.path } className='menu-option'> { menuOption.name } </Link>
            </li>
        )
    }


    return (
        <div className={ classDisplayMobile } id='navbarNavDropdown'>
            <Logo src='images/LogoTWSHorizontal.png' class='logo-header d-md-block d-lg-none'></Logo>
            <ul className='nav navbar-nav'> {
                options.map((menuOption,key) => {
                    const status = menuOption.active && isDesktop ? 'active' : ''
                    return( getOption(status,key,key,menuOption) )
                })
            } </ul>
        </div>
    )
}

const mapStateToProps = state => { return state }
const mapDispatchToProps = { changeNavSelected, changeIndexAccordion }

export default connect(mapStateToProps,mapDispatchToProps)(MainNav)