import React from 'react'
import { boxForServices } from '../../common/Constants'
import {Link} from 'react-router-dom';
import ScrollTopFunction from '../../../components/common/ScrollTopFunction'

export const Content = () => {
  return (
    <div className="bg-white tabs-box-area">
			<div className="section-full bg-white content-inner">
				<div className="container">
          <div className="row">
						<div className="col-lg-12">
              <div className="row mt-1">
                <div className="section-content row">
                  {
                    boxForServices.map((item, key)=>{
                      return (
                        <div key={key} className="col-lg-6 col-md-6 service-box style3">
                          <div className="icon-bx-wraper" data-name={item.dataName}>
                            <Link to={item.link} className="icon-cell" onClick={ () => { ScrollTopFunction() } }><i className={item.icon}></i> <h2 className="dlab-tilte">{item.title}</h2> </Link>
                            <div className="icon-content">
                              <p className="text-justify">{item.content}</p>
                            </div>
                          </div>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
						</div>
					</div>
        </div>
      </div>
    </div>
  )
}

export default Content;