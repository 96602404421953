import React, { useState, useEffect } from 'react'
import PageBanner from '../../../common/PageBanner'
const Fabrication = props => {

    return (
        <div className='bg-white'>
            <PageBanner title='Fabrication ELECTRICAL CONTRACTOR' img={{ name: 'commonBanner', type: 'jpg' }}></PageBanner>
        </div>
    )
}

export default Fabrication;



