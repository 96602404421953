import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import changeNavSelected from '../../../redux/actions/changeNavSelected'
import PageBanner from '../../common/PageBanner'
import ScrollTopFunction from '../../common/ScrollTopFunction'

const Search = props => {
    const { searchResults } = props.state

    useEffect(() => {
        props.changeNavSelected(50)
    }, [])

    let [ page, setPage ] = useState(0)
    let totalElement = searchResults.length
    const pagination = 5
    const totalPages = Math.ceil((totalElement/pagination)) ? Math.ceil((totalElement/pagination)) : 1
    let totalPagination = []

    for(let i=0; i<totalPages; i++) {
        totalPagination.push(i)
    }

    if(totalElement) {
        return (
            <section>
                <PageBanner title='Results' img={{ name: 'commonBanner', type: 'jpg' }}></PageBanner>
                <div className='content-area bg-white'>
                    <div className='container'>
                        {
                            searchResults.slice(page*pagination,page*pagination+pagination).map((item, key) => {
                                return(
                                    <div key={ key } className='blog-post blog-md clearfix'>
                                        <div className='dlab-post-media dlab-img-effect zoom-slow'>
                                            <img src={ 'images/search/' + item.img } alt=''/>
                                        </div>
                                        <div className='dlab-post-info'>
                                            <div className='dlab-post-title'>
                                                <h4 className='post-title'><a>{ item.title }</a></h4>
                                            </div>
                                            <div className='dlab-post-text'>
                                            <p>{ item.content.substr(0,250) + '...' }</p>
                                            </div>
                                            <div className='dlab-post-readmore'>
                                                <Link to={ item.path } onClick={ () => ScrollTopFunction() } title='READ MORE' rel='bookmark' className='site-button'>READ MORE
                                                    <i className='ti-arrow-right p-l5'></i>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                        <div className='pagination-bx clearfix text-center p-b40'>
                            <ul className='pagination'>
                                <li onClick={ () => { if(page>0) { setPage(page-1) }  } } className='previous pointer'><a><i className='ti-arrow-left'></i> Prev</a></li>
                                {
                                    totalPagination.map((item,key) => {
                                        return(
                                            <li key={ key } className={ key === page ? 'active pointer' : 'pointer' }>
                                                <a onClick={ () => { setPage(item) } } >{ item + 1 }</a>
                                            </li>
                                        )
                                    })
                                }
                                <li onClick={ () => { if(page < totalPagination.length -1) { setPage(page+1) }  } } className='next pointer'><a>Next <i className='ti-arrow-right'></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        )
    }

    return (
        <section>
            <PageBanner title='Results' img={{ name: 'commonBanner', type: 'jpg' }}></PageBanner>
            <div className='content-area p-b60 bg-white'>
                <div className='container'>
                    <h1 style={{ marginBottom: '0' }}> No Result Founds </h1>
                </div>
            </div>
        </section>
    )
}

const mapStateToProps = state => { return state }
const mapDispatchToProps = { changeNavSelected }

export default connect(mapStateToProps,mapDispatchToProps)(Search)
