import React from 'react'
import { contactInformation } from '../common/Constants'

export const ContactUs = () => {
    return (
        <div className='col-md-4 col-xl-3 col-lg-3 col-sm-12 footer-col-4'>
            <div className='widget widget_getintuch border-0'>
                <h5 className='m-b30 text-white'>Contact Us</h5>
                <ul style={{ fontSize:'15px' }}>
                    <li><i className='ti-location-pin'></i><strong>address</strong>{ contactInformation.address} <br/> {contactInformation.addressPart1 }</li>
                    <li><i className='ti-mobile'></i><strong>phone</strong>{ contactInformation.phone }</li>
                    <li><i className='ti-email'></i><strong>email</strong>{ contactInformation.email }</li>
                </ul>
            </div>
        </div>
    )
}

export default ContactUs
