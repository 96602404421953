import React from 'react'

const SectionYellow = ({ title, list }) => {
    return (
        <div className="max-w700 m-auto p-tb50 p-lr20">
                    <h2 className="text-white">{ title }</h2>
            <div className="container">
                <div className="text-white">
                    {
                        list.map((item,key) => {
                            return(
                                <div key={ key } className="icon-bx-wraper m-b30 left">
                                    <div className="icon-md">
                                    <a className="icon-cell text-white">
                                    <i></i>
                                    </a>
                                    </div>
                                        {/* <div className="icon-lg text-white m-b20"> <i className={item.icon }></i></div> */}
                                        <div className="icon-content">
                                            {/* <h5 className="dlab-tilte text-uppercase">{ item.name }</h5> */}
                                            <p>{ item.content }</p>
                                        </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default SectionYellow
