import React from 'react'
import TitlePage from '../../../common/TitlePage'
import ImageRadius from '../../../common/ImageRadius'
import ListBox from '../../../common/ListBox'
import SectionYellow from '../../../common/SectionYellow'
import SectionDark from '../../../common/SectionDark'

export const Content = () => {
    const propForEffects = { 'data-wow-duration': '2s', 'data-wow-delay': '0.3s' }
    const section = {
        title: 'Experienced Commercial Electricians',
        list: [
            { content: 'Breaker Box Wiring, installing and servicing breaker boxes.'},
            { content: 'Data Wiring & Networking Data installation and networking for optimum reliability and performance.'},
            { content: 'Electrical Outlet Installation Journeyman electrician for installing power outlets.' },
            { content: 'Electrical Inspections Occupancy inspections, safety inspections and more.'}
        ]
    }
    
    let list2 = [
        { name: 'Office Building & Office Parks', },
        { name: 'Restaurants' },
        { name: 'Retail Establishments' },
        { name: 'Shopping Centers and Malls' },
        { name: 'Churches, Synagogues and Temples' },
        { name: 'Many More!' }
    ]
    const section2 = {
        list: [
        { content: 'Installation of power systems, electronics, equipment, signage and more.', title: 'Electrical Wiring' },
        { content: 'Installation Ground Fault Circuit Interrupter installation for safety.', title: 'GFCI'}
    ]
    }
    return (
        <div className="content-block">
            <div className="container m-b60 customPage">
                <div className="section-full content-inner-1 about-bx">
                    {/* // ------------------------------ROW----------------------------------- */}
                    <div className="row mb-4">
                        {/* // ------------------------------COL 1----------------------------------- */}
                        <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                            <ImageRadius src="images/services/commercial_electrical.jpg"/>
                        </div>
                        {/* // ------------------------------COL 2----------------------------------- */}
                        <div className= "col-sm-12 col-md-6 col-lg-6 wow fadeInRight" { ...propForEffects }>
                            <div className="align-self-center">
                                <TitlePage title={ "Expert Commercial Electrician" } />
                                <p>
                                The commercial electrician businesses rely on, we provide contractors for power and lighting design, data networking, telephone line installation and more. We are a licensed company for installation, maintenance and repairs for all types of businesses. A skilled electric contractor for projects of any size, we also provide professionals for safety inspections and occupancy inspections. When you need an expert commercial electrician, you can count on us for the best service.
                                </p>
                                <TitlePage title={ "Equipment Upgrades" } />
                                <p>
                                Outdated power systems in many older buildings are not up to par with the efficiency and performance that the latest technologies deliver. As a result, hiring a professional to upgrade your systems for power, illumination, networking and voice communications often amounts to significant savings in energy usage and costs.
                                </p>
                            </div>
                        </div>
                    </div>
                    {/* // ------------------------------ROW----------------------------------- */}
                    <div className="row mb-4">
                        {/* // ------------------------------COL 1----------------------------------- */}
                        <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft" { ...propForEffects }>
                            <div className="align-self-center">
                                <TitlePage title={ "New Equipment Installations" } />
                                <p>
                                    To retrofit lights, our electrical contractors replace all outdated lamps and ballasts with newer, more energy efficient products that require less power. Our contractors install fluorescent, compact fluorescent and LED bulbs, as well as a variety of other types.
                                </p>
                                <TitlePage title={ "Commercial Electric Maintenance" } />
                                <p>
                                Properly maintaining power and lighting systems provides a significant cost savings for businesses. In addition, regularly scheduled maintenance helps you avoid inefficiencies and outages. Periodic upkeep is essential to ensure the productivity of your equipment, and also optimizing energy efficiency. Our electricians can help you design a plan for your overall maintenance needs; and we provide expert electric maintenance services. Our contractors also inspect lights and fixtures, and provide retrofitting solutions.
                                </p>
                            </div>
                        </div>
                        {/* // ------------------------------COL 2----------------------------------- */}
                        <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInRight" { ...propForEffects }>
                            {/* <ListBox list={ list2 }/> */}
                            <ImageRadius src="images/services/commercial_electrical3.jpg"/>
                        </div>
                    </div>
                </div>
                
            </div>
            {/* // ------------------------------ROW YELLOW SECTION----------------------------------- */}
            <div className="section-full box-about-list">
                    <div className="row spno">
                        {/* // ------------------------------COL 1----------------------------------- */}
                        <div className="col-lg-6 col-md-12 wow fadeInLeft" { ...propForEffects }>
                            <img src="images/services/commercial_electrical2.jpg" alt="" className="img-cover"/>
                        </div>
                        {/* // ------------------------------COL 2----------------------------------- */}
                        <div className="col-lg-6 col-md-12 bg-primary wow fadeInRight" { ...propForEffects }>
                        <SectionYellow { ...section } />
                        </div>
                    </div>
                </div>
                {/* // ------------------------------ROW DARK SECTION----------------------------------- */}
            <div className="section-full content-inner overlay-black-dark about-8-service bg-img-fix" style={{ backgroundImage: 'url(images/banner/commonBanner.jpg)', marginBottom: '0px' }}>
                    <div className="container">
                    <SectionDark { ...section2 } />
                    </div>
            </div>
        </div>
    )
}

export default Content;