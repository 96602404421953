import React from 'react'
import { Link } from 'react-router-dom'
import ScrollTopFunction from '../common/ScrollTopFunction'

const UserLink = props => {
  const menuFooter = [
    {
      path: '/',
      name: 'Home'
    }, {
      path: '/project-coordination',
      name: 'Project Coordination'
    }, {
      path: '/electrical-consulting',
      name: 'Electrical Consulting'
    }, {
      path: '/local-services-area',
      name: 'Local Services Area'
    }, {
      path: '/contact',
      name: 'Contact Us'
    }
  ]
  return (
    <div className='d-none d-sm-none d-md-block col-md-4 col-7 col-xl-3 col-lg-3 col-sm-6 footer-col-4' data-wow-delay='0.2s'>
      <div className="widget widget_services border-0">
        <h5 className='m-b30 text-white'>User Link</h5>
        <ul className='list-1'>
          {
            menuFooter.map((item,key) => {
              return(
                <li key={key}><Link onClick={ ScrollTopFunction } style={{ color: '#b0b0b0' }} to={item.path} >{item.name}</Link></li>
              )
            })
          }
        </ul>
      </div>
    </div>
  )
}

export default UserLink
