import React from 'react'
import {Link} from 'react-router-dom';
import ScrollTopFunction from '../../../common/ScrollTopFunction'

export const Content = () => {
  let lvss = [{
    id:"faq1",
    dataName:"01",
    icon:"fas fa-user-lock fa-4x",
    path:"access-control",
    title:"Access Control",
    content:"There are many ways to protect and secure a facility and employees. Industrial Electric is skilled in the installation of integrated, multi-technology approach to satisfy our customer’s needs."
},{
  id:"faq2",
  dataName:"02",
  icon:"fas fa-ethernet fa-4x",
  path:"fiber-optic",
  title:"Fiber Optics",
  content:"With electromagnetic interference immunity, small footprint, data capacity, and tremendous range length without repeaters fiber optics are becoming increasingly popular in building systems. "
},{
  id:"faq3",
  dataName:"03",
  icon:"fas fa-bell fa-4x",
  path:"fire-alarm",
  title:"FireAlarm",
  content:"There are many ways to protect and secure a facility and employees. Industrial Electric is skilled in the installation of integrated, multi-technology approach to satisfy our customer’s needs."
},{
  id:"faq4",
  dataName:"04",
  icon:"fas fa-tools fa-4x",
  path:"instrumentation-and-start-up",
  title:"Instrumentation and Start Up",
  content:"Our Industrial Electric Instrumentation group which is comprised of project managers, engineers and technicians provide full-service procurement, installation, calibration"
},{
  id:"faq5",
  dataName:"05",
  icon:"fas fa-sort-amount-up-alt fa-4x",
  path:"structure-cabling",
  title:"Structure Cabling",
  content:"Creating professional-looking labels for structured cabling systems has always been a challenge for the installer. Here's what you need to know to fill that gap in the professionalism of your cabling work."
},{
  id:"faq6",
  dataName:"06",
  icon:"fas fa-tty fa-4x",
  path:"telecommunications",
  title:"Telecommunications",
  content:"With the increasing demand for computer network installations, telecommunications grounding and bonding has become a growing opportunity for electrical contractors."
},{
  id:"faq7",
  dataName:"07",
  icon: "fas fa-tasks fa-4x",
  path:"testing-services",
  title:"Testing Services",
  content:"Testing and certification services to manufacturers of building integrated photovoltaic modules (BiPV), rack-mounted photovoltaic (PV) modules and mounting systems in conjunction with the 2014 revision of the standard, which introduces new PV module fire type classification and system rating tests."
}]
  return (
    <div className="bg-white tabs-box-area">
			<div className="section-full bg-white content-inner">
				<div className="container">
          <div className="row">
						<div className="col-lg-12">
							{/* <div className="sort-title clearfix text-center">
							</div> */}
              <div className="row mt-3">
              {
                lvss.map((item, key)=>{
                  return (
                    <div key={key} className="col-lg-6 col-md-6 service-box style3">
                      <div className="icon-bx-wraper" data-name={item.dataName}>
                          <Link to={item.path} className="icon-cell" onClick={ () => { ScrollTopFunction() } }><i className={item.icon}></i> <h2 className="dlab-tilte">{item.title}</h2> </Link>
                        <div className="icon-content">
                          <p className="text-justify">{item.content}</p>
                        </div>
                      </div>
                    </div>
                  )
                })
                }
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
  )
}

export default Content;