import React from 'react'
import ListBox from './ListBox'

const SectionTextOverRed = ({ title, text, lists = [] }) => {
    return (
        <div className="section-full call-action bg-primary wow fadeIn" data-wow-duration='2s' data-wow-delay='0.3s' style={{ marginBottom: '0px' }}>
            <div className="container">
                <div className="row text-white text-center">
                    <h2 className="title title_section_red">{ title }</h2>
                    <div className="row w-100">
                        {
                            lists.map((list, key) => {
                                return(
                                    <div key={ key } className="col-sm-12 col-md-6 col-lg-4">
                                        <ListBox list={ list } color="black"/>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <p className="text-center">
                        { text }
                    </p>
                </div>
            </div>
        </div>
    )
}

export default SectionTextOverRed
