import React from 'react'
import { Link } from 'react-router-dom'
import ScrollTopFunction from '../common/ScrollTopFunction'

const CallAction = () => {
    return (
        <div className='section-full call-action bg-primary wow fadeIn' data-wow-duration='2s' data-wow-delay='0.3s'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-9 text-white'>
                        <h2 className='title'>What makes us different </h2>
                        <p className='m-b0'>With the years TWS has grown thanks to our excellent and professional performance in every project, because of the end of every project quality is our main goal.</p>
                    </div>
                    <div className='col-lg-3 d-flex'>
                        <Link to='/contact' onClick={ () => ScrollTopFunction() } className='site-button btnhover8 white align-self-center outline ml-auto radius-xl outline-2'>Contact Us</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CallAction
