import React from 'react'

const SectionDark = ({ title, list }) => {
    return (
        <div className="text-white text-white">
            <div className="container">
                    {
                        list.map((item,key) => {
                            return(
                                <div key={ key } className="section-head text-white text-center icon-bx-wraper m-b30 left" style={{paddingBottom:'15px'}}>
                                    {/* <h2 className="title-box max-w800 m-auto">{ title }<span class="bg-primary"></span></h2> */}
                                    <div className="icon-md">
                                    <a className="icon-cell text-white">
                                    <i></i>
                                    </a>
                                    </div>
                                        {/* <div className="title-box max-w800 m-auto"> </div> */}
                                        <div className="icon-content">
                                        <h2 className="title-box max-w800 m-auto">{item.title }</h2>
                                            {/* <h5 className="dlab-tilte text-uppercase">{ item.name }</h5> */}
                                            <p>{ item.content }</p>
                                        </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
    )
}

export default SectionDark
