import React from 'react'
import TitlePage from '../../../common/TitlePage'
import ImageRadius from '../../../common/ImageRadius'

export const Content = () => {
    let propForEffects = { 'data-wow-duration': '2s', 'data-wow-delay': '0.3s' }
    return (
        <div className="container m-b60 customPage">
            <div className="section-full content-inner-1 about-bx">
                {/* // ------------------------------ROW----------------------------------- */}
                <div className="row mb-4">
                    {/* // ------------------------------COL 1----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                        <ImageRadius src="images/services/lighting_design_1.jpg"/>
                    </div>
                    {/* // ------------------------------COL 2----------------------------------- */}
                    <div className= "col-sm-12 col-md-6 col-lg-6 wow fadeInRight" { ...propForEffects }>
                        <div className="align-self-center">
                            <TitlePage title={ "Lighting Design Services" } />
                            <p>
                            Our electricians work on lighting design projects for many different types of businesses. Our contractors provide comprehensive solutions for power and lights. Our electricians also work on improvements to existing lighting systems with retrofits. Whether your project is simple or complex, our highly qualified technicians will exceed your expectations.
                            </p>
                            <TitlePage title={ "Complete Energy Efficient Lighting Solutions" } />
                            <p>
                            Creating impressive and original designs, we also offer energy efficient solutions for all types of businesses. Before developing the designs for your property, our electricians perform a daytime light and energy analysis to determine the brightness level needed for your facility. Using precise calculations and innovative techniques, our lighting solutions are practical and cost-effective.
                            </p>
                        </div>
                    </div>
                </div>
                {/* // ------------------------------ROW----------------------------------- */}
                <div className="row mb-4">
                    {/* // ------------------------------COL 1----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                        <div className="align-self-center">
                            <TitlePage title={ "Retrofitting Lights" } />
                            <p>
                            Our technicians can help, whether you need an electrician to install fixtures, retrofit existing equipment, or for complete design projects. Retrofitting involves replacing outdated lamps and ballasts with newer, more efficient products that require less power. We install energy efficient fluorescent, compact fluorescent, and LED bulbs, as well as a variety of other types of lamps. Our electricians also retrofit security and signage lights.
                            </p>
                            <TitlePage title={ "Lighting Design for Unique Atmospheres" } />
                            <p>
                            Our experts develop functional plans for custom designs that complement your decor. We’re a leading electrician for illumination solutions for retail establishments, shopping centers, restaurants, bars, night clubs and other attractions. We also provide solutions for medical buildings and facilities, offices, warehouses and more.
                            </p>
                        </div>
                    </div>
                    {/* // ------------------------------COL 2----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInRight m-b30" { ...propForEffects }>
                        <ImageRadius src="images/services/lighting_design_2.jpg"/>
                    </div>
                </div>
                {/* // ------------------------------ROW----------------------------------- */}
                <div className="row mb-4">
                    {/* // ------------------------------COL 1----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                        <ImageRadius src="images/services/lighting_design_3.jpg"/>
                    </div>
                    {/* // ------------------------------COL 2----------------------------------- */}
                    <div className= "col-sm-12 col-md-6 col-lg-6 wow fadeInRight" { ...propForEffects }>
                        <div className="align-self-center">
                            <TitlePage title={ "Lighting Control Systems" } />
                            <p>
                            Installing controls for illumination systems dramatically reduces utility costs. Our electricians install all types of automatic controls to improve energy efficiency. Power and control systems such as motion sensors, timers and dimmers reduce unnecessary power drains. Installing controls in bathrooms, storage rooms and closets ensures that the lamps do not stay on when the area is unoccupied. This often leads to a significant reduction of utility costs.
                            </p>
                            <p>
                            Contact the electrician calls for the best lighting design solutions. Give us a call at (919) 387-2220	, or contact us online.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Content;