import React from 'react'

const ExtraNav = () => {
    return (
        <div className='extra-nav'>
            <div className='extra-cell'>
                <button id='quik-search-btn' type='button' className='site-button-link'>
                    <i className='la la-search'></i>
                </button>
            </div>
        </div>
    )
}

export default ExtraNav