import React from 'react'
import TitlePage from '../../../common/TitlePage'
import ImageRadius from '../../../common/ImageRadius'
import ListBox from '../../../common/ListBox'
import SectionYellow from '../../../common/SectionYellow'

export const Content = () => {
    const propForEffects = { 'data-wow-duration': '2s', 'data-wow-delay': '0.3s' }
    const section = {
        title: 'Experienced Commercial Electricians',
        list: [
            { content: 'Manufacturing Facilities'},
            { content: 'Fabrication Facilities'},
            { content: 'Food Manufacturing and Packaging Facilities'}
        ]
    }
    let list1 = [
        { name: 'Lighting Retrofits & Solutions Custom lighting solutions and retrofits.' },
        { name: 'Energy Efficiency Solutions Optimization of power systems for improving energy efficiency.' },
        { name: 'PLC Services Programmable Logic Control centers for process automation.' },
        { name: 'Harmonic Mitigation Reduction and avoidance of fines for distorted electrical waveforms.' },
        { name: 'Standby Generator Systems Emergency generator installation up to 2 megawatts.' },
        { name: 'Carousel & Conveyor Wiring Seamless wiring for material handling equipment.' },
        { name: 'Specialty Voltages Services for 380 volt, 440y/254 volt and more.' }
    ]

    return (
        <div className="content-block">
            <div className="container m-b60 customPage">
                <div className="section-full content-inner-1 about-bx">
                    {/* // ------------------------------ROW----------------------------------- */}
                    <div className="row mb-4">
                        {/* // ------------------------------COL 1----------------------------------- */}
                        <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                            <ImageRadius src="images/services/industrial_services.jpg"/>
                        </div>
                        {/* // ------------------------------COL 2----------------------------------- */}
                        <div className= "col-sm-12 col-md-6 col-lg-6 wow fadeInRight" { ...propForEffects }>
                            <div className="align-self-center">
                                <TitlePage title={ "Industrial System Upgrades" } />
                                <p>
                                Upgrading your power systems and equipment helps to reduce energy consumption and lower your overhead costs. Lighting retrofit services improve the energy efficiency of businesses, providing a substantial reduction of utility costs. We are available if you need a professional to upgrade your power and lighting systems to increase productivity and efficiency.
                                </p>
                                <p>
                                We provide licensed contractors to deliver solutions for all types of facilities with outdated power and lighting systems. We are also a top electrician for industrial equipment upgrades, including conveyor and carousel systems and other material handling equipment.
                                </p>
                            </div>
                        </div>
                    </div>
                    {/* // ------------------------------ROW----------------------------------- */}
                    <div className="row mb-4">
                        {/* // ------------------------------COL 1----------------------------------- */}
                        <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                            <div className="align-self-center">
                            <TitlePage title={ "Industrial Electrical Contractors" } />
                                <p>
                                We are a top industrial electrician providing expert execution of design build solutions for businesses. Our electrical contractors work on installations, system upgrades, maintenance, troubleshooting and electric repairs. Contact us of you need a professional to help your businesses maximize energy efficiency, minimize down time and improve productivity.
                                </p>
                                <TitlePage title={ "Industrial Electric Installations" } />
                                <p>
                                We are the company businesses rely on for all types of industrial electric installations. Our master electricians design and build plans for power system layouts, wiring and installation of equipment, illumination systems, controllers and more. We provide electrical contractors to install wiring, distribution panels, transformers, circuits, generators, conveyor systems, security systems and other equipment and machinery.
                                </p>
                            </div>
                        </div>
                        {/* // ------------------------------COL 2----------------------------------- */}
                        <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInRight" { ...propForEffects }>
                            {/* <ListBox list={ list1 }/> */}
                            <ImageRadius src="images/services/industrial_services_3.jpg"/>
                        </div>
                    </div>
                    {/* // ------------------------------ROW----------------------------------- */}
                    <div className="row mb-4">
                        {/* // ------------------------------COL 1----------------------------------- */}
                        <div className= "col-sm-12 col-md-6 col-lg-6 wow fadeInLeft" { ...propForEffects }>
                            <div className="align-self-center">
                                <ImageRadius src="images/services/electrical_repair.jpg"/>
                            </div>
                        </div>
                        {/* // ------------------------------COL 2----------------------------------- */}
                        <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInRight m-b30" { ...propForEffects }>
                                <TitlePage title={ "Industrial Electrical Maintenance" } />
                                <p>
                                Regular maintenance of your equipment and power systems is essential for productivity, efficiency, and keeping energy costs low. Reducing the likelihood of outages and downtime, maintaining your equipment helps to prevent energy drains and unexpected fines.
                                </p>
                                <p>
                                If you need an industrial electrician for preventative and routine maintenance, we provide transformer oil sample testing, power distribution and switchgear cleaning and testing, thermal imaging and more.
                                </p>
                        </div>
                    </div>
                </div>
            </div>{/* // ------------------------------ROW YELLOW SECTION----------------------------------- */}
            <div className="section-full box-about-list" style={{ marginBottom: '0px' }}>
                    <div className="row spno">
                        {/* // ------------------------------COL 1----------------------------------- */}
                        <div className="col-lg-6 col-md-12 bg-primary wow fadeInLeft" { ...propForEffects }>
                        <SectionYellow { ...section } />
                        </div>
                        {/* // ------------------------------COL 2----------------------------------- */}
                        <div className="col-lg-6 col-md-12 wow fadeInRight" { ...propForEffects }>
                            <img src="images/services/industrial_services_2.jpg" alt="" className="img-cover"/>
                        </div>
                    </div>
                </div>
        </div>
    )
}
export default Content;