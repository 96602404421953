import React from 'react'
import { ourServices } from '../common/Constants'
import { Link } from 'react-router-dom'
import ScrollTopFunction from '../common/ScrollTopFunction'

const OurProject = () => {
  return (
    <div className="section-full content-inner bg-gray" id="services" style={{ paddingTop: '0px' }}>
      <div className="container">
        <div className="section-head text-black text-center">
            <h2 className="title">Our Services</h2>
            {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry has been the industry's standard dummy text ever since the been when an unknown printer.</p> */}
        </div>
        <div className="row">
          {
          ourServices.map((item, key)=> {
            return(
              <div key={key} className={ "col-lg-4 col-md-6 col-sm-12 m-b50 wow " + item.effect } data-wow-duration="2s" data-wow-delay="0.3s">
                <div className="dlab-box service-box-3">
                  <div className="dlab-media radius-sm dlab-img-overlay1">
                    <Link to={ item.link } className="icon-cell"><img src={ item.image } alt=""/></Link>
                  </div>
                  <Link onClick={ ScrollTopFunction } to={item.link}>
                    <div className="dlab-info">
                      <h4 className="title">
                        { item.title }
                      </h4>
                    </div>
                  </Link>
                </div>
              </div>
            )})
          }
          </div>
        </div>
      </div>
    )
}

export default OurProject
