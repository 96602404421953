import React from 'react'
import TitlePage from '../../../../common/TitlePage'
import ImageRadius from '../../../../common/ImageRadius'

export const Content = () => {
    let propForEffects = { 'data-wow-duration': '2s', 'data-wow-delay': '0.3s' }

    return (
        <div className="container m-b60 customPage">
            <div className="section-full content-inner-1 about-bx">
                {/* // ------------------------------ROW----------------------------------- */}
                <div className="row mb-4">
                    {/* // ------------------------------COL 1----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                        <ImageRadius src="images/services/structure_cabling.jpg"/>
                    </div>
                    {/* // ------------------------------COL 2----------------------------------- */}
                    <div className= "col-sm-12 col-md-6 col-lg-6 wow fadeInRight" { ...propForEffects }>
                        <div className="align-self-center">
                            <TitlePage title={ "Structure Cabling" } />
                            <p>
                                Creating complete and professional-looking labels for structured cabling systems has always been a challenge for the installer. Here's what you need to know to fill that gap in the professionalism of your cabling work.Commercially available handheld label printers are terrific for labeling wires, but they aren't designed for labeling structured cabling systems. Consequently, installers who try to.
                            </p>
                            <p>
                                Commercially available handheld label printers are terrific for labeling wires, but they aren't designed for labeling structured cabling systems. Consequently, installers who try to adapt them for the specific application of cabling don't fare very well. Today's network administration standards, such as TIA/EIA 606 with its guidelines for color-coding of network elements, further complicate the installer's task.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Content;