import React from 'react'
import PageBanner from '../../../common/PageBanner'
import Content from './Content'

const ComercialServices  = () => {
    return (
        <div className='bg-white'>
            <PageBanner title='Comercial Services' img={{ name: 'commonBanner', type: 'jpg' }}></PageBanner>
            <Content></Content>
        </div>
    )
}

export default ComercialServices