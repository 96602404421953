import React from 'react'
import TitlePage from '../../../common/TitlePage'
import ImageRadius from '../../../common/ImageRadius'

export const Content = () => {
    let propForEffects = { 'data-wow-duration': '2s', 'data-wow-delay': '0.3s' }
    return (
        <div className="container m-b60 customPage">
            <div className="section-full content-inner-1 about-bx">
                {/* // ------------------------------ROW----------------------------------- */}
                <div className="row mb-4">
                    {/* // ------------------------------COL 1----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                        <ImageRadius src="images/services/specialty_voltage_1.jpg"/>
                    </div>
                    {/* // ------------------------------COL 2----------------------------------- */}
                    <div className= "col-sm-12 col-md-6 col-lg-6 wow fadeInRight" { ...propForEffects }>
                        <div className="align-self-center">
                            <TitlePage title={ "TWS for Specialty Voltages" } />
                            <p>
                            Designing efficient power systems involves selecting the optimum system voltages. Our expert industrial electricians have extensive experience with a broad range of specialty voltage systems. Therefore we have a precise process for maximizing your energy efficiency and equipment functioning.
                            </p>
                            <TitlePage title={ "Equipment Inspection and Testing" } />
                            <p>
                            To assess the voltage requirements of your equipment, our industrial electrical contractors perform all of the necessary inspections. While using a variety of instruments, our skilled electricians run the tests required to troubleshoot problems and isolate inefficiencies. We will ensure the safety of your power system and ensure compliance with the National Electrical Code (NEC).
                            </p>
                        </div>
                    </div>
                </div>
                {/* // ------------------------------ROW----------------------------------- */}
                <div className="row mb-4">
                    {/* // ------------------------------COL 1----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                        <div className="align-self-center">
                            <TitlePage title={ "High Voltage Services" } />
                            <p>
                            High voltage industrial applications require service from a specialist to ensure safety and proper installation. Our industrial electricians have extensive experience with high voltage distribution systems. In addition, our electrical contractors work with a variety of voltages, including 380 volt and 440y/254 volt and other European and Asian Supply Systems. Our electricians also perform arc flash assessments, and maintenance on transformers, tap chargers and circuit breakers.
                            </p>
                            <TitlePage title={ "Voltage Optimization" } />
                            <p>
                            Optimization provides energy savings by adjusting your equipment for more efficient power consumption. Consequently, our industrial electrical contractors provide services for correcting over-voltage and under-voltage issues. To reduce your power usage, our electricians can set your equipment to minimum specifications, without compromising productivity.
                            </p>
                        </div>
                    </div>
                    {/* // ------------------------------COL 2----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInRight" { ...propForEffects }>
                        <ImageRadius src="images/services/specialty_voltage_2.jpg"/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Content;