import React from 'react'
import TitlePage from '../../../common/TitlePage'
import ImageRadius from '../../../common/ImageRadius'

export const Content = () => {
    let propForEffects = { 'data-wow-duration': '2s', 'data-wow-delay': '0.3s' }
    return (
        <div className="container m-b60 customPage">
            <div className="section-full content-inner-1 about-bx">
                {/* // ------------------------------ROW----------------------------------- */}
                <div className="row mb-4">
                    {/* // ------------------------------COL 1----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                        <ImageRadius src="images/services/conveyor_1.jpg"/>
                    </div>
                    {/* // ------------------------------COL 2----------------------------------- */}
                    <div className= "col-sm-12 col-md-6 col-lg-6 wow fadeInRight" { ...propForEffects }>
                        <div className="align-self-center">
                            <TitlePage title={ "Production Equipment Installation" } />
                            <p>
                            Carousel and conveyor wiring projects are best handled by a qualified industrial electrician. Updating your conveyor layout and installing new equipment is a valuable investment in improving the productivity and output of your operations. Keeping your carousel and conveyor systems running at optimal capacity enables your business to be competitive. Hiring an electric contractor for maintenance helps prevent breakdowns that impede production.
                            </p>
                            <TitlePage title={ "Carousel and Conveyor Wiring and Installation" } />
                            <p>
                            Our contractors deliver seamless wiring solutions for carousel and conveyor systems as well as other material handling equipment. In addition, our technicians install integrated conveyor systems including vertical conveyors, multilevel conveying equipment, carousels, tabletops, rack systems and more. We also install pallet systems, high speed equipment and robotic systems.
                            </p>
                        </div>
                    </div>
                </div>
                {/* // ------------------------------ROW----------------------------------- */}
                <div className="row mb-4">
                    {/* // ------------------------------COL 1----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                        <div className="align-self-center">
                            <TitlePage title={ "Equipment Upgrades and Expansions" } />
                            <p>
                            Whether you are installing updated equipment or an entire production and conveyor system, you can count on our experienced contractors for safe, efficient and economical carousel and conveyor wiring. If you are expanding your production line or changing the layout of carousel and conveyor equipment in your facility, our industrial experts will help make the process easier for you.
                            </p>
                            <TitlePage title={ "Process Controls" } />
                            <p>
                            To fully automate production and material handling systems, our technicians install a complete range of controls and programmers. Process controls help reduce energy usage, and therefore lower utility costs.
                            </p>
                        </div>
                    </div>
                    {/* // ------------------------------COL 2----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInRight" { ...propForEffects }>
                        <ImageRadius src="images/services/conveyor_2.jpg"/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Content;