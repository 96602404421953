import React from 'react'
import TitlePage from '../../../common/TitlePage'
import ImageRadius from '../../../common/ImageRadius'

export const Content = () => {
    let propForEffects = { 'data-wow-duration': '2s', 'data-wow-delay': '0.3s' }
    return (
        <div className="container m-b60 customPage">
            <div className="section-full content-inner-1 about-bx">
                {/* // ------------------------------ROW----------------------------------- */}
                <div className="row mb-4">
                    {/* // ------------------------------COL 1----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                        <ImageRadius src="images/services/office_wiring_1.jpg"/>
                    </div>
                    {/* // ------------------------------COL 2----------------------------------- */}
                    <div className= "col-sm-12 col-md-6 col-lg-6 wow fadeInRight" { ...propForEffects }>
                        <div className="align-self-center">
                            <TitlePage title={ "Office Wiring" } />
                            <p>
                            Professional office electric wiring is essential, due to the fact that the daily operations of many businesses relies heavily on communications and information systems. Therefore business communication, data and power systems must be extremely reliable to meet the expectations and needs of customers. Because of our expertise and years of experience, we are the electrician trusts for seamless office wiring.
                            </p>
                            <TitlePage title={ "Office Electric Services by Skilled Contractors" } />
                            <p>
                            We offer a full range of office and electric wiring solutions, including telephone and data networking, illumination systems and more. In addition, our skilled contractors also install intercom and paging systems, audio and video equipment, surveillance and security systems, and fire alarms. Our electricians ensure a strong foundation for powerful systems and networks.
                            </p>
                        </div>
                    </div>
                </div>
                {/* // ------------------------------ROW----------------------------------- */}
                <div className="row mb-4">
                    {/* // ------------------------------COL 1----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                        <div className="align-self-center">
                            <TitlePage title={ "Electric Rewiring Solutions" } />
                            <p>
                            Since older office buildings that are not equipped to handle the power and connectivity needs of today, they often require new electric wiring. Main panel upgrades are often the solution for improving energy efficiency and performance. Office rewiring improves the efficiency of energy distribution for many businesses. Our licensed contractors complete all types of office projects flawlessly and on time.
                            </p>
                            <TitlePage title={ "Equipment and Network Upgrades" } />
                            <p>
                            Equipment and network upgrades for keeping up with advances in technology are essential for most businesses. Our electric contractors wire and install the latest equipment for many offices in the area. Additionally, we assist businesses with wireless network installation and WLAN networking setup. Our clients rely on us when they need a professional to upgrade their networks and install new equipment. We ensure flawless transitions to new networks and equipment for offices of all sizes.
                            </p>
                        </div>
                    </div>
                    {/* // ------------------------------COL 2----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInRight m-b30" { ...propForEffects }>
                        <ImageRadius src="images/services/office_wiring_2.jpg"/>
                    </div>
                </div>
                <div className="row mb-4">
                    {/* // ------------------------------COL 1----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                        <ImageRadius src="images/services/office_wiring_3.jpg"/>
                    </div>
                    {/* // ------------------------------COL 2----------------------------------- */}
                    <div className= "col-sm-12 col-md-6 col-lg-6 wow fadeInRight" { ...propForEffects }>
                        <div className="align-self-center">
                            <TitlePage title={ "Structured Cabling" } />
                            <p>
                            Structured cabling combines your telephone, data, video, security and audio systems electric wiring into a single distribution network, while signals for your electronics are sent through a structured networking distribution panel via direct cable lines. Consequently, direct lines provide the strongest connection available to all of your equipment. Our electric contractors install pre-configured and custom distribution panels for structured cabling system solutions.
                            </p>
                            <p>
                            Our qualified electricians handle a full range of office electric wiring needs. Working on your timeline, we’ll complete your project at the price that we quote you. To discuss your office electric project, call us today at (919) 387-2220.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Content;