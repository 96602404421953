import React from 'react'
import ListBox from '../../../../common/ListBox'
import TitlePage from '../../../../common/TitlePage'
import ImageRadius from '../../../../common/ImageRadius'

export const Content = () => {
    let propForEffects = { 'data-wow-duration': '2s', 'data-wow-delay': '0.3s' }
    let list1 = [
        { name: 'Door interlocks' },
        { name: 'Intrusion detection' },
        { name: 'Addressable fire alarm systems' }
    ]

    return (
        <div className="container m-b60 customPage">
            <div className="section-full content-inner-1 about-bx">
                {/* // ------------------------------ROW----------------------------------- */}
                <div className="row mb-4">
                    {/* // ------------------------------COL 1----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                        <ImageRadius src="images/services/access_control.jpg"/>
                    </div>
                    {/* // ------------------------------COL 2----------------------------------- */}
                    <div className= "col-sm-12 col-md-6 col-lg-6 wow fadeInRight" { ...propForEffects }>
                        <div className="align-self-center">
                            <TitlePage title={ "Access Control" } />
                            <p>
                            There are many ways to protect and secure a facility and employees. Industrial Electric is skilled in the installation of integrated, multi-technology approach to satisfy our customer’s needs. Many of our customers do not want to rely on a single product set to safeguard their people and assets. We have found that a multilayered, multi-technology approach offers a variety of solutions to meet a wide range of threats.
                            </p>
                            <ListBox list={ list1 }/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Content;