import React from 'react'
import { contactInformation } from './Constants'

const LeftButton = () => {
    return (
        <a id="callnowbutton" href={ 'tel:' + contactInformation.phone } className='bt-buy-now theme-btn'>
            <i className='fa fa-phone' style={{position: "absolute",top: "16px", left: "16px"}}></i>
            {/* <span>Call Us</span> */}
        </a>
    )
}

export default LeftButton
