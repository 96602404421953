import React from 'react'
import TitlePage from '../../../common/TitlePage'
import ImageRadius from '../../../common/ImageRadius'

export const Content = () => {
    let propForEffects = { 'data-wow-duration': '2s', 'data-wow-delay': '0.3s' }
    return (
        <div className="container m-b60 customPage">
            <div className="section-full content-inner-1 about-bx">
                {/* // ------------------------------ROW----------------------------------- */}
                <div className="row mb-4">
                    {/* // ------------------------------COL 1----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                        <ImageRadius src="images/services/outdoor_lighting_1.jpg"/>
                    </div>
                    {/* // ------------------------------COL 2----------------------------------- */}
                    <div className= "col-sm-12 col-md-6 col-lg-6 wow fadeInRight" { ...propForEffects }>
                        <div className="align-self-center">
                            <TitlePage title={ "Expert Outdoor Lighting Solutions" } />
                            <p>
                            Our electricians work on outdoor lighting repairs and installation projects for a range of businesses. We install and repair parking lot pole lighting, and all types of outdoor illumination systems. Our contractors also service lighting for signage and other low voltage outdoor lighting.
                            </p>
                            <TitlePage title={ "Energy Efficient Lighting" } />
                            <p>
                            Knowledgeable about the latest technologies for energy efficient lighting, you can count on us to provide the most cost-effective solutions. We install daylight and motion sensor fixtures, and all types of controls for illumination systems. If you have old fixtures and lamps that need to be updated, our electricians provide expert retrofitting services.
                            </p>
                        </div>
                    </div>
                </div>
                {/* // ------------------------------ROW----------------------------------- */}
                <div className="row mb-4">
                    {/* // ------------------------------COL 1----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                        <div className="align-self-center">
                            <TitlePage title={ "Illumination Systems & More" } />
                            <p>
                            Our electrical contractors install outdoor flood lights, walkway lights, as well as a complete range of security lights. Experienced in lighting design, we also provide services for landscape illumination, patios and ceiling fans. In business, our company is a leader in lighting solutions.
                            </p>
                            <p>
                            Our electricians also provide a complete range of services, including generator installation, office electric wiring, GFCI installation, data networking, phone wiring and more. If you need an electrician for outdoor lighting or other services, give us a call at (919) 387-2220	, or contact us online.
                            </p>
                        </div>
                    </div>
                    {/* // ------------------------------COL 2----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInRight m-b30" { ...propForEffects }>
                        <ImageRadius src="images/services/outdoor_lighting_2.jpg"/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Content;