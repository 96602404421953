import React from 'react'

const SectionTextOverImage = ({ image, title, text  }) => {
    return (
        <div className="image_cover section-full wow fadeIn" data-wow-duration='2s' data-wow-delay='0.6s' style={{ backgroundImage: 'url(' + image + ')' }}>
            <div className="container" style={{  paddingTop: '22px' }}>
                <h1 className="title_over_img">{ title }</h1>
                <p className="text_over_img">
                    { text }
                </p>
            </div>
        </div>
    )
}

export default SectionTextOverImage
