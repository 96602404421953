import React, { useEffect } from 'react'
import Init from './Init'

const Scroltop = () => {

    useEffect(() => {
        Init.initScrollTop()
    },[])

    return (
        <button className='scroltop style2 radius' type='button'>
            <i className='fa fa-arrow-up'></i>
        </button>
    )
}

export default Scroltop