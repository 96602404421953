import React from 'react'
import TitlePage from '../../../common/TitlePage'
import ImageRadius from '../../../common/ImageRadius'

export const Content = () => {
    let propForEffects = { 'data-wow-duration': '2s', 'data-wow-delay': '0.3s' }
    return (
        <div className="container m-b60 customPage">
            <div className="section-full content-inner-1 about-bx">
                {/* // ------------------------------ROW----------------------------------- */}
                <div className="row mb-4">
                    {/* // ------------------------------COL 1----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                        <ImageRadius src="images/services/phone_wiring_1.jpg"/>
                    </div>
                    {/* // ------------------------------COL 2----------------------------------- */}
                    <div className= "col-sm-12 col-md-6 col-lg-6 wow fadeInRight" { ...propForEffects }>
                        <div className="align-self-center">
                            <TitlePage title={ "Phone Wiring & Networking" } />
                            <p>
                            Seamless phone wiring and networking is the foundation for a dependable communication system. Since stable business telephone systems are critical for the operations of most businesses, hiring a professional is advised. To provide service that is reliable and consistent, communication is key. Our electricians provide telephone and networking solutions for businesses of all sizes.
                            </p>
                            <TitlePage title={ "Telephone Installation" } />
                            <p>
                            We work with large companies to install major phone systems across many departments. Our electrical contractors handle all aspects of phone system installation, including cable routing, switchboards, switching equipment, wiring frames, power sources, surge-protection devices, equipment assembly, configuration, connectivity and more.
                            </p>
                        </div>
                    </div>
                </div>
                {/* // ------------------------------ROW----------------------------------- */}
                <div className="row mb-4">
                    {/* // ------------------------------COL 1----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                        <div className="align-self-center">
                            <TitlePage title={ "Telephone Troubleshooting and Repairs" } />
                            <p>
                            Our highly qualified electricians can inspect your existing communications and networking systems to identify any deficiencies. If you are experiencing problems with your telephone system, our contractors are available to perform troubleshooting functions and make repairs to your phone wiring and networking systems. Also, our technicians perform testing and evaluations on all of our installations to ensure peak performance.
                            </p>
                            <TitlePage title={ "Qualified Technicians for Any Project" } />
                            <p>
                            Our experienced team of electric contractors provide quality installation and networking services fast and efficiently. In addition, our electricians also work on re-installations, and extensions to existing phone systems. Delivering seamless networking for all of our phone installation projects, our expert technicians always ensure that every project meets all building and safety codes. For reliable phone wiring and communications systems, call the electrician trusts at (919) 387-2220	. You can also make an appointment online.
                            </p>
                        </div>
                    </div>
                    {/* // ------------------------------COL 2----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInRight m-b30" { ...propForEffects }>
                        <ImageRadius src="images/services/phone_wiring_2.jpg"/>
                    </div>
                </div>
                {/* // ------------------------------ROW----------------------------------- */}
                <div className="row mb-4">
                    {/* // ------------------------------COL 1----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                        <ImageRadius src="images/services/phone_wiring_3.jpg"/>
                    </div>
                    {/* // ------------------------------COL 2----------------------------------- */}
                    <div className= "col-sm-12 col-md-6 col-lg-6 wow fadeInRight" { ...propForEffects }>
                        <div className="align-self-center">
                            <TitlePage title={ "Structured Cabling" } />
                            <p>
                            Structured cabling is a solution for networking a combination of data, telephone, audio, video, security and other electronic systems in a building. A pre-configured or structured networking distribution panel is used to connect with cables, sending signals through direct lines to different rooms. This is because direct lines provide the strongest connection possible to each of your electronic devices. Therefore we provide structured cabling solutions along with phone installation for businesses.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Content;