import React from 'react'
import {Link} from 'react-router-dom';

const DzetItem = props => {
  return (
    <div className="col-lg-4 col-md-6 col-sm-6 m-b20">
      <div className="icon-bx-wraper bx-style-1 p-lr20 p-tb20 center seth radius-sm">
        <div className="icon-lg text-primary m-b20"> <Link to="/" className="icon-cell"><i className={props.icon}></i></Link> </div>
        <div className="icon-content">
  <h5 className="dlab-tilte text-uppercase">{props.title}</h5>
          <p className="text-center">{props.content}</p>
        </div>
      </div>
    </div>
  )
}

export default DzetItem
