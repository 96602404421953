import React from 'react'
import TitlePage from '../../../common/TitlePage'
import ListBox from '../../../common/ListBox'
import ImageRadius from '../../../common/ImageRadius'
import SectionYellow from '../../../common/SectionYellow'
import SectionDark from '../../../common/SectionDark'

export const Content = () => {
    const propForEffects = { 'data-wow-duration': '2s', 'data-wow-delay': '0.3s' }
    const section = {
        title: 'Comprehensive Project Management Services',
        list: [
        { content: 'Working closely with owners and plant managers during the design and layout stage to help them make the most efficient decisions regarding their projects.'},
        { content: 'Providing valuable input on the availability of electrical utility layout and sizing at the time of building purchase.'},
        { content: 'Preparing layout of manufacturing and processing systems to deliver the best flow.'}
    ]
}
const section2 = {
    list: [
        { content: 'Disassembling equipment in the old facility and reconnecting it at the new location.', title: 'Coordinating Plant Relocation i.e. '},
        { content: 'On installation of electrical services at strategic locations to boost efficiency and help cut cost, while maintaining proper production flow.', title: 'Delivering Expert Advice'},
        { content: 'Of multiple building utilities and coordination of line change-outs in a running manufacturing plant.', title: 'Assisting in the Layouts'}
]
}
    return (
        <div className="content-block">
            <div className="container m-b60 customPage">
                <div className="section-full content-inner-1 about-bx">
                    {/* // ------------------------------ROW----------------------------------- */}
                    <div className="row mb-4">
                        {/* // ------------------------------COL 1----------------------------------- */}
                        <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                            <ImageRadius src="images/services/porject_coordination_1.jpg"/>
                        </div>
                        {/* // ------------------------------COL 2----------------------------------- */}
                        <div className= "col-sm-12 col-md-6 col-lg-6 wow fadeInRight" { ...propForEffects }>
                            <div className="align-self-center">
                                <TitlePage title={ "Efficient Planning, Scheduling, Surveying and Reporting for Time and Cost Control" } />
                                <p>
                                Rely on expert project management services from TWS, Inc. to complete your electrical and mechanical work on time and within budget. Whether you need us for a new construction project, facility upgrade or expansion, our hands-on approach and attention to detail facilitates seamless completion of your work. The team assigned to your jobsite will keep you informed of progress at every stage. How much or little you want to be involved is entirely up to you.
                                </p>
                                <p>
                                Rom commissioning highly-specialized equipment for industrial plants to setting up electrical systems for commercial buildings and ensuring safety and security systems are up-to-code in multi-family residences, we have successfully handled installs and retrofits of varying scope and complexity. Discuss your upcoming projects with us. We will be happy to customize a service plan to meet your unique needs.
                                </p>
                            </div>
                        </div>
                    </div>
                    {/* // ------------------------------ROW----------------------------------- */}
                    <div className="row mb-4">
                        {/* // ------------------------------COL 1----------------------------------- */}
                        <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                            <div className="align-self-center">
                                <p>
                                Customers of TWS, enjoy tailored solutions, detailed attention and exceptional service. We take the time to fully understand your needs so that we can develop an efficient project management plan to meet them. Extensive experience with complex installs and a commitment to following best practices safeguard your interests.
                                </p>
                                <p>
                                Being experienced in all aspects of electrical and mechanical contracting, our project management team is able to seamlessly co-ordinate all aspects of your project.
                                </p>
                            </div>
                        </div>
                        {/* // ------------------------------COL 2----------------------------------- */}
                        <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInRight" { ...propForEffects }>
                        <ImageRadius src="images/services/porject_coordination_2.jpg"/>
                            {/* <TitlePage title={ "List Title Example" } /> */}
                        </div>
                    </div>
                </div>
            </div> {/* // ------------------------------ROW YELLOW SECTION----------------------------------- */}
            <div className="section-full box-about-list">
                    <div className="row spno">
                        {/* // ------------------------------COL 1----------------------------------- */}
                        <div className="col-lg-6 col-md-12 wow fadeInLeft" { ...propForEffects }>
                            <img src="images/services/porject_coordination_3.png" alt="" className="img-cover"/>
                        </div>
                        {/* // ------------------------------COL 2----------------------------------- */}
                        <div className="col-lg-6 col-md-12 bg-primary wow fadeInRight" { ...propForEffects }>
                        <SectionYellow { ...section } />
                        </div>
                    </div>
                </div>
                {/* // ------------------------------ROW DARK SECTION----------------------------------- */}
            <div className="section-full content-inner overlay-black-dark about-8-service bg-img-fix" style={{ backgroundImage: 'url(images/banner/commonBanner.jpg)', marginBottom: '0px' }}>
                    <div className="container">
                    <SectionDark { ...section2 } />
                    </div>
            </div>
        </div>
    )
}

export default Content;