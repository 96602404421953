import React from 'react'
import TitlePage from '../../../common/TitlePage'
import ImageRadius from '../../../common/ImageRadius'

export const Content = () => {
    let propForEffects = { 'data-wow-duration': '2s', 'data-wow-delay': '0.3s' }
    return (
        <div className="container m-b60 customPage">
            <div className="section-full content-inner-1 about-bx">
                {/* // ------------------------------ROW----------------------------------- */}
                <div className="row mb-4">
                    {/* // ------------------------------COL 1----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                        <ImageRadius src="images/services/energy_efficiency_1.jpg"/>
                    </div>
                    {/* // ------------------------------COL 2----------------------------------- */}
                    <div className= "col-sm-12 col-md-6 col-lg-6 wow fadeInRight" { ...propForEffects }>
                        <div className="align-self-center">
                            <TitlePage title={ "Solutions for Energy Efficiency" } />
                            <p>
                            With advances in technology, options for improving energy efficiency have grown in recent years. There are a number of approaches available for lowering your electric costs and increasing productivity. Our industrial electrical contractors have the knowledge and experience to help you reduce your energy consumption and maximize the power systems in your facility.
                            </p>
                            <TitlePage title={ "Energy Audits" } />
                            <p>
                            To identify the appropriate power saving solutions for your business, our industrial electricians conduct an audit of your facility. During the inspection, we examine your machinery, wiring, control systems and all energy reliant equipment. Based on the calculations made during the audit of your facility, our industrial electrical contractors develop a plan for improving the efficiency of your power systems.
                            </p>
                        </div>
                    </div>
                </div>
                {/* // ------------------------------ROW----------------------------------- */}
                <div className="row mb-4">
                    {/* // ------------------------------COL 1----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                        <div className="align-self-center">
                            <TitlePage title={ "Energy Efficiency Solutions" } />
                            <p>
                            Our energy solutions for businesses are comprehensive in scope. We optimize power management systems, troubleshooting and replacing outdated equipment and technologies. Our contractors also work on energy efficient lighting solutions, including retrofits and controls. We also install solar energy lighting and the latest technologies. Other solutions that we offer to help you save energy include work on your HVAC system, high efficiency motor and pump replacement, harmonic mitigation, boiler replacement and more.
                            </p>
                            <TitlePage title={ "Incentives for Energy Efficiency" } />
                            <p>
                            In addition to lowering your electricity bill and increasing efficiency, our solutions also have other benefits. Rebates for lighting upgrades and other power saving measures are often available from your local utility provider. This can significantly reduce the cost of implementing energy efficient technologies in your facility. Tax credits and federal grants for efficiency may be also be available for your business.
                            </p>
                        </div>
                    </div>
                    {/* // ------------------------------COL 2----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInRight" { ...propForEffects }>
                        <ImageRadius src="images/services/energy_efficiency_2.jpg"/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Content;