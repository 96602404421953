import React, {useState} from 'react'
import axios from 'axios'

const Form = props => {

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [subject, setSubject] = useState('')
  const [message, setMessage] = useState('')

  const mapSetState = {
    'name': setName,
    'email': setEmail,
    'phone': setPhone,
    'subject': setSubject,
    'message': setMessage
  }

  const handleChange = e => {
    mapSetState[e.target.name](e.target.value)
  }

  const resetField = () => {
    Object.keys(mapSetState).forEach( key => {
        mapSetState[key]('')
    })
  }

  const handleSubmit = e => {
    e.preventDefault();
    if(name === '' || email === '' || phone === '' || message === '' || subject === '') {
      alert('fills required fields...')
      return;
    }
    axios({
        method: "post",
        url: `${process.env.REACT_APP_API}`,
        data: { name, email, msg: message, phone, subject},
        crossDomain: true,
        mode: 'cors',
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'JSON',
        }
    })
    .then(result => {
        let data = result.data
        alert(data.message)
        resetField();
    })
    .catch( error => console.log(error.message) )
  }

  return (
    <div className="col-lg-6 m-b30">
      <div className="p-a30 bg-gray clearfix radius-sm">
        <h3>Send Message Us</h3>
        <div className="dzFormMsg"></div>
          <input type="hidden" value="Contact" name="dzToDo" />
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <div className="input-group">
                  <input onChange={ handleChange } value={ name } name="name" type="text" required className="form-control" placeholder="Your Name"/>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <div className="input-group">
                  <input onChange={handleChange} value={email} name="email" type="text" required className="form-control" placeholder="Your Email Id" />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <div className="input-group">
                <input onChange={handleChange} value={phone} name="phone" type="text" required className="form-control" placeholder="Phone"/>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <div className="input-group">
                <input onChange={handleChange} value={subject} name="subject" type="text" required className="form-control" placeholder="Subject"/>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <div className="input-group">
                <textarea onChange={handleChange} value={message} name="message" className="form-control" required placeholder="Your Message..."></textarea>
                </div>
              </div>
            </div>
            <div className="col-md-12">
            <div className="form-group">
              <div className="input-group">
              <div className="g-recaptcha" data-callback="verifyRecaptchaCallback" data-expired-callback="expiredRecaptchaCallback"></div>
                {/* <input className="form-control d-none" style=display:none;" data-recaptcha="true" required data-error="Please complete the Captcha"/> */}
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <button name="submit" type="submit" value="Submit" className="site-button" onClick={ handleSubmit }> <span>Submit</span> </button>
          </div>
        </div>
      </div>
    </div>
  )
}


export default Form