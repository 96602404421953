import React from 'react'
import TitlePage from '../../../common/TitlePage'
import ImageRadius from '../../../common/ImageRadius'
import SectionTextOverImage from '../../../common/SectionTextOverImage'
import SectionTextOverRed from '../../../common/SectionTextOverRed'

export const Content = () => {
    const propForEffects = { 'data-wow-duration': '2s', 'data-wow-delay': '0.3s' }
    const list1 = [
        { name: 'Customer safety support', },
        { name: 'Hazard-specific inspections' }
    ]
    const list2 = [
        { name: 'PPE issued to employees' },
        { name: 'Site safety reviews' }
    ]
    const list3 = [
        { name: 'Employee safety recognition' },
        { name: 'On-site “tool box” talks' }
    ]

    return (
        <div className="content-block">
            {/* // ------------------------------Row----------------------------------- */}
            <div className="section-full box-about-list">
                {/* // ------------------------------RED SECTION----------------------------------- */}
                <SectionTextOverRed
                title="YOUR SKILLED TRADES STAFFING ADVANTAGE"
                text="You can’t afford to leave your project in the hands of unqualified workers - project delays and shoddy work can cripple your business. We are specialized in the construction staffing industry, providing America’s best skilled tradespeople for medium and large contractors in the commercial and industrial space."
                />
            </div>
            {/* // ------------------------------Row----------------------------------- */}
            <div className="section-full box-about-list">
                <SectionTextOverImage
                image="images/services/staffing.jpg"
                title="WHY CHOOSE TWS?"
                text="Reduce your Workers Comp exposure Reduce your unemployment costs Only paying for labor when you need it Bid work out of your normal realm Access to the nation’s largest database."
                />
            </div>
            {/* // ------------------------------Row----------------------------------- */}
            <div className="container m-b80 m-t20 customPage">
                <div className="section-full content-inner-1 about-bx">
                    <div className="row">
                        {/* // ------------------------------COL 1----------------------------------- */}
                        <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft" { ...propForEffects }>
                            <ImageRadius src="images/services/electrical_wiring_2.jpg"/>
                        </div>
                        {/* // ------------------------------COL 2----------------------------------- */}
                        <div className= "col-sm-12 col-md-6 col-lg-6 wow fadeInRight" { ...propForEffects }>
                            <div className="align-self-center">
                                <h1 className="font-weight-600">OUR SERVICES</h1>
                                <TitlePage title={ "TEMPORARY:" } />
                                <p>
                                Lower your labor costs and gain access to an workforce at a moment’s notice.
                                </p>
                                <TitlePage title={ "TEMP-TO-HIRE:" } />
                                <p>
                                Reduce your risk of hiring mistakes by evaluating talent on-the-job before making a full-time hiring decision. “Test drive before you buy.”
                                </p>
                                <TitlePage title={ "PAYROLLING:" } />
                                <p>
                                Place employees you recruit on our payroll while reducing your Workers’ Compensation exposure. This option also reduces the administrative burden and cost associated with placing employees on your payroll and allows you the same benefit as temp-to-hire.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* // ------------------------------Row----------------------------------- */}
            <div className="section-full box-about-list" style={{ paddingBottom: '58px' }}>
                {/* // ------------------------------RED SECTION----------------------------------- */}
                <SectionTextOverRed
                title="SAFETY IS OUR #1 PRIORITY. WE OFFER THE FOLLOWING:"
                text="We also provide safety screening, orientation, and site-specific training for
                all skilled tradepeople through our partnership with a leading Safety Management company."
                lists={[ list1, list2, list3 ]}
                />
            </div>
        </div>
    )
}

export default Content;