import React from 'react'
import TitlePage from '../../../../common/TitlePage'
import ImageRadius from '../../../../common/ImageRadius'

export const Content = () => {
    let propForEffects = { 'data-wow-duration': '2s', 'data-wow-delay': '0.3s' }

    return (
        <div className="container m-b60 customPage">
            <div className="section-full content-inner-1 about-bx">
                {/* // ------------------------------ROW----------------------------------- */}
                <div className="row mb-4">
                    {/* // ------------------------------COL 1----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                        <ImageRadius src="images/services/telecomunications.jpg"/>
                    </div>
                    {/* // ------------------------------COL 2----------------------------------- */}
                    <div className= "col-sm-12 col-md-6 col-lg-6 wow fadeInRight" { ...propForEffects }>
                        <div className="align-self-center">
                            <TitlePage title={ "Telecomunications" } />
                            <p>
                                With the increasing demand for computer network installations, telecommunications grounding and bonding has become a growing opportunity for electrical contractors. Although similar grounding principles apply, understanding the telecommunications terminology and special considerations has been a challenge. To help wade through the industry's jargon, this article will define terms and discuss the basic schematics of a properly grounded and bonded telecommunications network.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Content;