import React from 'react'
import TitlePage from '../../../common/TitlePage'
import ImageRadius from '../../../common/ImageRadius'

export const Content = () => {
    let propForEffects = { 'data-wow-duration': '2s', 'data-wow-delay': '0.3s' }
    return (
        <div className="container m-b60 customPage">
            <div className="section-full content-inner-1 about-bx">
                {/* // ------------------------------ROW----------------------------------- */}
                <div className="row mb-4">
                    {/* // ------------------------------COL 1----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                        <ImageRadius src="images/services/breaker_1.jpg"/>
                    </div>
                    {/* // ------------------------------COL 2----------------------------------- */}
                    <div className= "col-sm-12 col-md-6 col-lg-6 wow fadeInRight" { ...propForEffects }>
                        <div className="align-self-center">
                            <TitlePage title={ "Breaker Box Wiring and Service Contractor" } />
                            <p>
                            Connecting the circuit breakers in your power system, a breaker box is also known as a fuse box, service panel or electrical panel. Licensed electrical contractors are qualified to work on service panels, including electric panel and breaker box wiring, circuit breaker installation and more. Our professional contractors have years of experience servicing this equipment.
                            </p>
                            <p>
                            Breaker box installation must be done correctly to ensure the performance and safety of your power system. Proper wiring is important for protecting electronics and equipment from overloads. Businesses hire our contractors to upgrade their power systems and equipment. We work on breaker box installation and all types of related services. If you plan to add equipment or electronics that exceed the power requirements of your current system, we install secondary or sub-panels to expand the capacity of power systems.
                            </p>
                        </div>
                    </div>
                </div>
                {/* // ------------------------------ROW----------------------------------- */}
                <div className="row mb-4">
                    {/* // ------------------------------COL 1----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                        <div className="align-self-center">
                            <TitlePage title={ "Breaker Box Repairs and More" } />
                            <p>
                            The skilled contractors at TWS work on breaker box projects for a range of businesses. Our electricians in Houston work on installation of breaker boxes, circuit breakers, sub-panels and more. We also provide technicians to troubleshoot and repair service panels and components. With more than 20 years of experience, you can count on us for the best and most reliable contractors.
                            </p>
                            <TitlePage title={ "Dependable Service When You Need It" } />
                            <p>
                            A 24 hour emergency electrician, we provide electric panel service when you need to get your business back up and running. Reliable and efficient, our contractors complete projects as quickly as possible on your timeline. We complete repairs at the price we quote you, so you won’t have to worry about going over budget. For breaker box wiring in the surrounding areas, call us at (919) 387-2220	, or contact us online.
                            </p>
                        </div>
                    </div>
                    {/* // ------------------------------COL 2----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInRight m-b30" { ...propForEffects }>
                        <ImageRadius src="images/services/breaker_2.jpg"/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Content;