import React from 'react'
import { Link } from 'react-router-dom';
import ScrollTopFunction from '../common/ScrollTopFunction'

const Slide = ({ slide }) => {

    const handleClick = () => {
		ScrollTopFunction()
	}

    const getlayerVideo = slide => {
        if(slide.type === 'video') {
            let mediaPath = 'media/video/' + slide.imgName + '.' + slide.format;
            return(
                <div className="rs-background-video-layer"
                    data-forcerewind="on"
                    data-volume="mute"
                    data-videowidth="100%"
                    data-videoheight="100%"
                    data-videomp4={ mediaPath }
                    data-videopreload="auto"
                    data-videoloop="loop"
                    data-aspectratio="16:9"
                    data-autoplay="true"
                    data-autoplayonlyfirsttime="false">
                </div>
            )
        } else {
            return('');
        }
    };

    const getlayer1 = () => {
        return(
            <div className="tp-caption tp-shape tp-shapewrapper tp-overlay-pera banner-curv"
                id="slide-100-layer-1"
                data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']"
                data-y="['middle','middle','middle','middle']" data-voffset="['0','0','0','0']"
                data-width="full"
                data-height="full"
                data-whitespace="nowrap"
                data-type="shape"
                data-basealign="slide"
                data-responsive_offset="on"
                data-responsive="off"
                data-frames='[
                    {"delay":0,"speed":1500,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},
                    {"delay":"wait","speed":300,"frame":"999","ease":"nothing"}
                ]'
                data-textalign="['left','left','left','left']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
                style={{
                    zIndex: 6
                }}>
            </div>
        )
    };

    const getlayer2 = () => {
        return(
            <div className="tp-caption tp-resizeme"
                id="slide-100-layer-2"
                data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']"
                data-y="['bottom','bottom','bottom','bottom']" data-voffset="['0','0','0','0']"
                data-width="full-proportional"
                data-height="full-proportional"
                data-whitespace="nowrap"
                data-type="image"
                data-basealign="slide"
                data-responsive_offset="on"
                data-frames='[
                    {"delay":500,"speed":2000,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},
                    {"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}
                ]'
                data-textalign="['inherit','inherit','inherit','inherit']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
                style={{
                    zIndex: 5
                }}>
                    <img src={ "images/slider/" + slide.imgName + '.' + slide.format } alt="" data-ww="['full-proportional','full-proportional','full-proportional','full-proportional']" data-hh="['full-proportional','full-proportional','full-proportional','full-proportional']" width="1920" height="1280" data-no-retina/>
            </div>
        )
    };

    const getlayer4 = () => {
        return(
            <div className="tp-caption tp-resizeme rs-parallaxlevel-1"
                id="slide-100-layer-4"
                data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']"
                data-y="['bottom','bottom','bottom','bottom']" data-voffset="['-100','330','230','350']"
                data-fontsize="['450','205','300','100']"
                data-lineheight="['550','240','240','240']"
                data-width="none"
                data-height="none"
                data-whitespace="nowrap"
                data-type="text"
                data-responsive_offset="on"
                data-frames='[
                    {"delay":1300,"speed":2000,"frame":"0","from":"sX:2;opacity:0;fb:20px;","to":"o:1;fb:0;","ease":"Power3.easeInOut"},
                    {"delay":"wait","speed":300,"frame":"999","to":"opacity:0;fb:0;","ease":"Power3.easeInOut"}
                ]'
                data-textalign="['center','center','center','center']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
                style={{
                    zIndex: 6,
                    whiteSpace: 'nowrap',
                    fontSize: '205px',
                    lineHeight: '240px',
                    fontWeight: '700',
                    color: 'rgba(255,255,255,0.6)',
                    fontFamily: "'Roboto Condensed', sans-serif"
                }}>
                <div className="rs-looped rs-wave" data-speed="3" data-angle="0" data-radius="2px" data-origin="50% 50%">WORKER</div>
            </div>
        )
    };

    const getlayer5 = () => {
        return(
            <div className="tp-caption tp-resizeme rs-parallaxlevel-1"
                id="slide-100-layer-5"
                data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']"
                data-y="['bottom','bottom','bottom','bottom']" data-voffset="['-50','-100','-100','-100']"
                data-width="none"
                data-height="none"
                data-whitespace="nowrap"
                data-type="image"
                data-responsive_offset="on"
                data-frames='[
                    {"delay":850,"speed":2000,"frame":"0","from":"sX:1.1;sY:1.1;opacity:0;fb:20px;","to":"o:1;fb:0;","ease":"Power3.easeInOut"},
                    {"delay":"wait","speed":300,"frame":"999","to":"opacity:0;fb:0;","ease":"Power3.easeInOut"}
                ]'
                data-textalign="['inherit','inherit','inherit','inherit']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
                style={{
                    zIndex: 10
                }}>
                <div className="rs-looped rs-wave"  data-speed="5" data-angle="0" data-radius="3px" data-origin="50% 50%">
                    <img src={ "images/slider/" + slide.imgNameBox + '.' + slide.formatBox } alt="" data-ww="['682px','682px','682px','430px']" data-hh="['842px','842px','842px','531px']" width="407" height="200" data-no-retina/>
                </div>
            </div>
        )
    };

    const getlayer6 = () => {
        return(
            <div className="tp-caption   tp-resizeme rs-parallaxlevel-2"
                id="slide-100-layer-6"
                data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']"
                data-y="['bottom','bottom','bottom','bottom']" data-voffset="['-80','-80','-80','-80']"
                data-width="none"
                data-height="none"
                data-whitespace="nowrap"
                data-type="image"
                data-responsive_offset="on"
                data-frames='[
                    {"delay":250,"speed":5000,"frame":"0","from":"y:100px;rZ:15deg;opacity:0;fb:20px;","to":"o:1;fb:0;","ease":"Power4.easeOut"},
                    {"delay":"wait","speed":300,"frame":"999","to":"opacity:0;fb:0;","ease":"Power3.easeInOut"}
                ]'
                data-textalign="['inherit','inherit','inherit','inherit']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
                style={{
                    zIndex: 9
                }}>
                <div className="rs-looped rs-wave"  data-speed="10" data-angle="0" data-radius="5px" data-origin="50% 50%">
                    <img src='images/slider/box-bg2.png' alt="" data-ww="['529px','529px','529px','300px']" data-hh="['742px','742px','742px','420px']" width="1025" height="500" data-no-retina/>
                </div>
            </div>
        )
    }

    const getlayer9 = () => {
        return(
            <div className="tp-caption tp-shape tp-shapewrapper tp-resizeme"
                id="slide-100-layer-9"
                data-x="['center','center','center','center']" data-hoffset="['-4','-4','-4','-4']"
                data-y="['bottom','bottom','bottom','bottom']" data-voffset="['221','201','301','300']"
                data-width="['390','390','390','180']"
                data-height="2"
                data-whitespace="nowrap"
                data-type="shape"
                data-responsive_offset="on"
                data-frames='[
                    {"delay":"bytrigger","speed":500,"frame":"0","from":"sX:0;opacity:1;","to":"o:1;","ease":"Power3.easeInOut"},
                    {"delay":"bytrigger","speed":300,"frame":"999","to":"sX:0;opacity:1;","ease":"Power3.easeInOut"}
                ]'
                data-textalign="['inherit','inherit','inherit','inherit']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
                data-lasttriggerstate="reset"
                style={{
                    zIndex: 13,
                    backgroundColor: 'rgba(255,255,255,1)'
                }}>
            </div>
        )
    };

    return(
        <section>
            { getlayer1() }
            { getlayer2() }
            { getlayer4() }
            { getlayer5() }
            { getlayer6() }
            { getlayer9() }
            {/* <!-- [ for video ]
            { getlayerVideo(slide) }--> */}
        </section>
    )
}

export default Slide