import React from 'react'
import TitlePage from '../../../common/TitlePage'
import ImageRadius from '../../../common/ImageRadius'

export const Content = () => {
    let propForEffects = { 'data-wow-duration': '2s', 'data-wow-delay': '0.3s' }
    return (
        <div className="container m-b60 customPage">
            <div className="section-full content-inner-1 about-bx">
                {/* // ------------------------------ROW----------------------------------- */}
                <div className="row mb-4">
                    {/* // ------------------------------COL 1----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                        <ImageRadius src="images/services/electrical_inspection_1.jpg"/>
                    </div>
                    {/* // ------------------------------COL 2----------------------------------- */}
                    <div className= "col-sm-12 col-md-6 col-lg-6 wow fadeInRight" { ...propForEffects }>
                        <div className="align-self-center">
                            <TitlePage title={ "Electrical Safety and Inspections" } />
                            <p>
                            We are the licensed electrician Houston calls for electrical inspections and occupancy inspections. Inspections do not have to be worrisome and stressful when you hire a qualified professional. Our experts help businesses ensure that official inspections to go as smoothly as possible. We also provide advice on how to improve the performance of your power systems and components.
                            </p>
                            <TitlePage title={ "Electrical Safety Inspections" } />
                            <p>
                            Electrical safety inspections are an important requirement for property owners. Since this is a top priority, routine safety inspections are essential. Wear and tear on components of a power system over the years often leads to hazardous conditions. Replacing old wiring and other hazards is necessary to keep a structure safe. We provide electricians who meticulously inspect utility boxes, circuits, breakers, outlets, and all other components and equipment to ensure that it is code compliant and safe.
                            </p>
                        </div>
                    </div>
                </div>
                {/* // ------------------------------ROW----------------------------------- */}
                <div className="row mb-4">
                    {/* // ------------------------------COL 1----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                        <div className="align-self-center">
                            <TitlePage title={ "Energy Audit for Energy Efficiency" } />
                            <p>
                            Power system inspections are helpful for improving energy efficiency as well as reducing utility costs. An inspection for the purpose of improving energy efficiency is also referred to as an energy audit. Upgrades to power systems, components or equipment with the energy efficient technologies available today often provides a significant savings on utility costs.
                            </p>
                            <p>
                            Our technicians provide you with a comprehensive energy analysis to assess the usage levels of your equipment and locate any leaks in the system. We correct drains and leaks to optimize your efficiency and lower your electric costs. Businesses often improve energy efficiency with illumination system controls or fixture retrofits. In addition, electric contractors improve the energy efficiency of power systems with services such as harmonic mitigation.
                            </p>
                        </div>
                    </div>
                    {/* // ------------------------------COL 2----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInRight m-b30" { ...propForEffects }>
                        <ImageRadius src="images/services/electrical_inspection_2.jpg"/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Content;