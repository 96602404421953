import React from 'react'
import ListBox from '../../../common/ListBox'
import TitlePage from '../../../common/TitlePage'

export const Content = () => {
  let list1 = [
    {
      name: 'Looking to design an electrical system for a new office?',
      icon: 'ti-bolt',
      class: 'col-lg-12 col-md-12 col-sm-12 p-lr0'
    },
    {
      name: 'Need to upgrade to energy-efficient LED lights in your shopping centre?',
      icon: 'ti ti-bolt',
      class: 'col-lg-12 col-md-12 col-sm-12 p-lr0'
    },
    {
      name: 'Want to increase security in your multi-family residential building?',
      icon: 'ti ti-bolt',
      class: 'col-lg-12 col-md-12 col-sm-12 p-lr0'
    }
  ]
  let list2 = [
    {
      name: 'Design & Build Engineering: Our design/build expertise translates to high-efficiency electrical and lighting systems that can be cost-efficiently installed and maintained.',
      icon: 'ti ti-bolt',
      class: 'col-lg-12 col-md-12 col-sm-12 p-lr0'
    },
    {
      name: 'Electrical Retrofits & Upgrades: Extensive experience with redesigning lighting and power system layouts has helped hundreds of customers seamlessly transition to the latest energy-efficient solutions.',
      icon: 'ti ti-bolt',
      class: 'col-lg-12 col-md-12 col-sm-12 p-lr0'
    },
    {
      name: 'Safety & Security Systems Installation: Property owners and managers in the North-East as well as in Michigan, Minnesota, Georgia, Texas, Indiana, Mississippi, North and South Carolina, rely on our sophisticated systems to help keep their properties and occupants safe.',
      icon: 'ti ti-bolt',
      class: 'col-lg-12 col-md-12 col-sm-12 p-lr0'
    },
    {
      name: 'Rebate Program Assistance: Work with government agencies to enroll in rebate programs.',
      icon: 'ti ti-bolt',
      class: 'col-lg-12 col-md-12 col-sm-12 p-lr0'
    }
    // {
    //   name: '24/7 Emergency Repair Service: We are ready and available whenever you need us. A round-the-clock service call system provides prompt response to restore efficient and safe functioning of your electrical systems during non-business hours.',
    //   icon: 'ti ti-bolt',
    //   class: 'col-lg-12 col-md-12 col-sm-12 p-lr0'
    // }
  ]
  return (
    <div className="container mt-5">
      <div className="row">
        <TitlePage title="Cost-Efficient Solutions for New Installs and Retrofits"/>
        <div className="col-lg-6 col-md-12">
          <img className="radius-sm w-100" src="images/services/commercial_services.jpg" alt=""/>
        </div>
        <div className="col-lg-6 col-md-12">
          <ListBox list={ list1 }/>
          <p className="text-justify">
            At TWS, we can configure a system that will provide you with optimal functionality, reliability and cost-efficiency. Our electrical team works in close partnership with you to ensure that your needs are fully understood and met. With high-efficiency electrical and lighting solutions that are customized to the unique requirements of your commercial property, power outages and utility bills are greatly reduced. Discuss your electrical needs with us. We are happy to tailor a service plan for your business.
            {/* <br/>
            Commercial property owners and managers across the country depend on our extensive experience for timely and within-budget completion of the most complex installs. Each project receives detailed attention from a team of trained and certified electricians who are committed to delivering quality work. We help hundreds of clients in New Jersey, Pennsylvania, and Delaware trim their energy costs without compromising aesthetics or security of their commercial premises. We can help you too. */}
          </p>
        </div>
      </div>
      <div className="row">
        <TitlePage title="Trusted TWS Offering A Range of Services"/>
        <div className="col-lg-6 col-md-12">
          <p className="text-justify">
            At TWS, our customers enjoy forward-thinking solutions, quality workmanship and personalized attention. From design-build of the latest lighting systems to LED retrofits, preventative maintenance and skilled repairs, we can efficiently handle all your electrical work. Our commercial services include:
          </p>
          <p className="text-justify">
            Customers of TWS, enjoy high-efficiency lighting and electrical solutions backed by quality materials and skilled workmanship. Licensed and insured electricians apply the latest technology and safety codes to keep your buildings, retail centers and government institutions cost-efficiently lit, secure and compliant. Whether it is a complete install, rewiring or preventative maintenance, each client receives the same detailed attention. You will rest easy knowing that projects are completed at the specified price and time without compromising quality of materials or workmanship. With our extensive capabilities, quick response and exceptional service, we aim to make you a customer for life.
          </p>
        </div>
        <div className="col-lg-6 col-md-12">
          <ListBox list={ list2 }/>
        </div>
      </div>
    </div>
  )
}

export default Content;