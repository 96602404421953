import React, { useEffect } from 'react'
import Init from '../common/Init'
import { OurCustomers } from '../common/Constants'

export const Componente = () => {

	useEffect(() => {
		Init.initDzCarrouselCustomer()
	}, [])

    return (
			<div className="section-full dlab-we-find bg-img-fix p-t20 p-b20 bg-white wow fadeIn" data-wow-duration="2s" data-wow-delay="0.6s">
				<div className="container">
					<div className="section-content">
						<div className="client-logo-carousel mfp-gallery gallery owl-btn-center-lr owl-carousel owl-btn-3">
							{
								OurCustomers.map((customer,key) => {
									return(
										<div key={ key } className="item">
											<div className="ow-client-logo">
												<div className="client-logo">
													<img src={ customer.logo } alt=""/>
												</div>
											</div>
										</div>
									)
								})
							}
						</div>
					</div>
				</div>
			</div>
    )
}
export default Componente;