import React from 'react'
import ContactUs from './ContactUs'
import Copyright from './Copyright';
import Company from './Company';
import UserLink from './UserLink';
import Gallery from './Gallery';

const Footer = () => {
    return (
        <footer className='site-footer' id='footer'>
            <div className='footer-top' style={{ backgroundImage:'url(images/background/bg3.png)', backgroundSize:' contain' }}>
                <div className='container'>
                    <div className='row'>
                        <ContactUs/>
                        <Company/>
                        <UserLink/>
                        <Gallery/>
                    </div>
                </div>
            </div>
            <Copyright/>
        </footer>
    )
}

export default Footer
