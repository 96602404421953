import React from 'react';
import { BrowserRouter as Router, Switch, Route  } from 'react-router-dom'
import { Provider } from 'react-redux'
import store from './redux/store'
import Header from './components/header'
import Content from './Content'
import Footer from './components/footer'
import Login from './components/login'
import LeftButton from './components/common/LeftButton';
import Scroltop from './components/common/Scroltop';
import LoadingArea from './components/common/LoandingArea'


import NoFound from './components/common/NoFound';

import { navs } from './components/common/Constants'

const App = () => {
  return (
    <Provider store={ store }>
      <Router>
        <Switch>
          <Route exact path={ getArrayPaths(navs) }>
            <LoadingArea/>
            <Header/>
              <Content/>
              <LeftButton/>
              <Footer/>
            <Scroltop/>
          </Route>
          <Route exact path='/login'>
            <LoadingArea/>
            <Login/>
          </Route>
          <Route path='*'>
            <LoadingArea/>
            <NoFound/>
          </Route>
        </Switch>
      </Router>
    </Provider>
  )
}

const getArrayPaths = (navs) => {
  let arrayPaths = navs.reduce((acc,curr) => {
    if(curr.items && curr.items.length > 0) {
      return [...acc, curr.path, ...getArrayPaths(curr.items)]
    }
    return [...acc,curr.path, '/search-results']
  },[])
  return arrayPaths
  // return [...arrayPaths, '/construction']
}

export default App;
