import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import changeNavSelected from '../../../../../redux/actions/changeNavSelected'
import PageBanner from '../../../../common/PageBanner'
import Content from './Content'

const TestingServices = props => {

    useEffect(() => {
        props.changeNavSelected(2)
    }, [])

    return (
        <div className='bg-white'>
            <PageBanner title='Testing Services' img={{ name: 'commonBanner', type: 'jpg' }}/>
            <Content/>
        </div>
    )
}

const mapStateToProps = state => { return state }
const mapDispatchToProps = { changeNavSelected }

export default connect(mapStateToProps,mapDispatchToProps)(TestingServices)