import React from 'react'
import ListBox from '../../../../common/ListBox'
import TitlePage from '../../../../common/TitlePage'
import ImageRadius from '../../../../common/ImageRadius'

export const Content = () => {
    let propForEffects = { 'data-wow-duration': '2s', 'data-wow-delay': '0.3s' }
    let list1 = [
        { name: 'Inner duct and pathway installation' },
        { name: 'Single mode and multimode risers' },
        { name: 'Free standing and rack mounted cabinets' },
        { name: 'Splicing and terminations certified' }
    ]

    return (
        <div className="container m-b60 customPage">
            <div className="section-full content-inner-1 about-bx">
                {/* // ------------------------------ROW----------------------------------- */}
                <div className="row mb-4">
                    {/* // ------------------------------COL 1----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                        <ImageRadius src="images/services/fiber_optic.jpg"/>
                    </div>
                    {/* // ------------------------------COL 2----------------------------------- */}
                    <div className= "col-sm-12 col-md-6 col-lg-6 wow fadeInRight" { ...propForEffects }>
                        <div className="align-self-center">
                            <TitlePage title={ "Fiber Optic" } />
                            <p>
                            With electromagnetic interference immunity, small footprint, data capacity, and tremendous range length without repeaters fiber optics are becoming increasingly popular in building systems. Industrial Electric is an industry leader in accommodating the need and demand of this type of cabling. We specialize in a variety of fiber optic uses to include:
                            </p>
                            <ListBox list={ list1 }/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Content;