import React from 'react'

const UnderConstructionPage = () => {
    let style = {
        marginTop: '30px',
        paddingLeft: '25%'
    }
    return (
        <div className="bg-white tabs-box-area">
            <div className="section-full bg-white content-inner">
                <div className="container">
                    <img style={ style } alt='' src='images/underconstructionpage.jpg'></img>
                </div>
            </div>
        </div>
    )
}

export default UnderConstructionPage