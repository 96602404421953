import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import changeNavSelected from '../../../../redux/actions/changeNavSelected'
import PageBanner from '../../../common/PageBanner'
import Content from './Content'

const ComercialElectrialServices  = props => {

    useEffect(() => {
        props.changeNavSelected(3)
    }, [])

    return (
        <div className='bg-white'>
            <PageBanner title='Comercial Electrical Services' img={{ name: 'commonBanner', type: 'jpg' }}></PageBanner>
            <Content></Content>
        </div>
    )
}

const mapStateToProps = state => { return state }
const mapDispatchToProps = { changeNavSelected }

export default connect(mapStateToProps,mapDispatchToProps)(ComercialElectrialServices)