import React from 'react'
import ListBox from '../../../../common/ListBox'
import TitlePage from '../../../../common/TitlePage'
import ImageRadius from '../../../../common/ImageRadius'

export const Content = () => {
    let propForEffects = { 'data-wow-duration': '2s', 'data-wow-delay': '0.3s' }
    let list1 = [
        { name: 'Access control' },
        { name: 'Door interlocks' }
    ]
    let list2 = [
        { name: 'Intrusion detection' },
        { name: 'UPS systems' }
    ]

    return (
        <div className="container m-b60 customPage">
            <div className="section-full content-inner-1 about-bx">
                {/* // ------------------------------ROW----------------------------------- */}
                <div className="row mb-4">
                    {/* // ------------------------------COL 1----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                        <ImageRadius src="images/services/fire_alarm.jpg"/>
                    </div>
                    {/* // ------------------------------COL 2----------------------------------- */}
                    <div className= "col-sm-12 col-md-6 col-lg-6 wow fadeInRight" { ...propForEffects }>
                        <div className="align-self-center">
                            <TitlePage title={ "Fire Alarm" } />
                            <p>
                                Even if you're not ready to take the plunge into fire alarm system design and installation just yet, you should still know the fundamentals in order to perform emergency work. This includes knowing how to disarm the control panel of a deranged system and troubleshoot the heads, pull stations, horns, and zone wiring so that the equipment goes back online, restoring fire protection for the building.
                            </p>
                            <div className="row">
                                <div className= "col-sm-6 col-md-6">
                                    <ListBox list={ list1 }/>
                                </div>
                                <div className= "col-sm-6 col-md-6">
                                    <ListBox list={ list2 }/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* // ------------------------------ROW----------------------------------- */}
                <div className="row mb-4">
                    {/* // ------------------------------COL 1----------------------------------- */}
                    <div className= "col-sm-12 col-md-12 col-lg-12 wow fadeInRight" { ...propForEffects }>
                    <ImageRadius src="images/services/fire_alarm_2.jpg"/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Content;