import React from 'react'
import TitlePage from '../../../common/TitlePage'
import ImageRadius from '../../../common/ImageRadius'

export const Content = () => {
    let propForEffects = { 'data-wow-duration': '2s', 'data-wow-delay': '0.3s' }
    return (
        <div className="container m-b60 customPage">
            <div className="section-full content-inner-1 about-bx">
                {/* // ------------------------------ROW----------------------------------- */}
                <div className="row mb-4">
                    {/* // ------------------------------COL 1----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                        <ImageRadius src="images/services/harmonic_1.jpg"/>
                    </div>
                    {/* // ------------------------------COL 2----------------------------------- */}
                    <div className= "col-sm-12 col-md-6 col-lg-6 wow fadeInRight" { ...propForEffects }>
                        <div className="align-self-center">
                            <TitlePage title={ "Harmonic Mitigation" } />
                            <p>
                            Harmonic mitigation solutions increase the reliability and capacity of your power systems. Extending the life of your equipment, harmonic mitigation improves efficiency while also reducing maintenance costs. We are the professional electrical contractor businesses call for harmonic mitigation services.
                            </p>
                            <TitlePage title={ "What is Harmonic Mitigation?" } />
                            <p>
                            Harmonics are distorted waveforms that produce extra heat that cannot be converted into usable energy. Since this increases the consumption of electricity, harmonics cause problems on your power grid. This leads to utility fines and increased maintenance costs for facilities with heavy motor, lighting and AFD loads. Because it causes wear and damage to electronics and motors, harmonics reduce the life of your equipment.
                            </p>
                        </div>
                    </div>
                </div>
                {/* // ------------------------------ROW----------------------------------- */}
                <div className="row mb-4">
                    {/* // ------------------------------COL 1----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                        <div className="align-self-center">
                            <TitlePage title={ "Electricians for Diagnosing Harmonics" } />
                            <p>
                            Addressing harmonic problems proactively helps you avoid system failures as well as unplanned major expenses. Problems related to harmonic distortion can be difficult to identify. Our skilled industrial electricians have many years of experience in diagnosing harmonic problems. Our contractors perform the necessary calculations to determine how to correct the power usage of your equipment. We are a top industrial contractor for mitigation solutions to support the optimal performance of your equipment and systems.
                            </p>
                            <TitlePage title={ "Harmonic Mitigation Services" } />
                            <p>
                            An industrial electric contractor performs mitigation services to ensure the quality and management of your power systems compliance with IEEE standards. Our contractors have the knowledge to block harmonics at their source. We provide electricians experienced in installing 18-pulse converters, active front end drives, active harmonic filters, fixed passive harmonic filters, switched passive harmonic filters, and harmonic blocking filters. We handle harmonic mitigation projects of all sizes.
                            </p>
                        </div>
                    </div>
                    {/* // ------------------------------COL 2----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInRight" { ...propForEffects }>
                        <ImageRadius src="images/services/harmonic_2.jpg"/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Content;