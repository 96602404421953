import React from 'react'
import TitlePage from '../../../common/TitlePage'
import ImageRadius from '../../../common/ImageRadius'
import SectionYellow from '../../../common/SectionYellow'
import SectionDark from '../../../common/SectionDark'

export const Content = () => {
    const propForEffects = { 'data-wow-duration': '2s', 'data-wow-delay': '0.3s' }
    const section = {
            title: 'Our Multi-family Residential Services',
            list: [
            { content: 'Electrical Design & Layout Assistance: Property managers across the North-East as well as in Georgia, Texas, Indiana, Mississippi, Michigan, Minnesota, North and South Carolina rely on our expertise to design an efficient and reliable electrical system.'},
            { content: 'Liaising With Your Utility Company: Guaranteeing primary power will be distributed properly is critical to system efficiency and safety.'},
            { content: 'Service Feeder Sizing & Layout Advice: Provide sizing and layout of the service feeders to your facility', icon: 'flaticon-factory' }
        ]
    }
    const section2 = {
        list: [
            { content: 'Our high-efficiency lighting systems for driveways and parking areas keep your premises well-lit and safe in an economical and environment-friendly manner.', title: 'External Lighting Services'},
            { content: 'From redesigning your electrical and lighting system to upgrading your rewiring and installing new LED lighting, we can help enhance your energy-efficiency and savings.', title: 'Facility Electrical Upgrades'},
            { content: 'Let us help boost facility efficiency by evaluating your current electrical, safety and security systems to detect and fix possible issues.', title: 'Preventative Maintenance'}
    ]
    }
    return (
        <div className="content-block">
            <div className="container m-b60 customPage">
                <div className="section-full content-inner-1 about-bx">
                    {/* // ------------------------------ROW----------------------------------- */}
                    <div className="row mb-4">
                        {/* // ------------------------------COL 1----------------------------------- */}
                        <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                            <ImageRadius src="images/services/multifamily_1.jpg"/>
                        </div>
                        {/* // ------------------------------COL 2----------------------------------- */}
                        <div className= "col-sm-12 col-md-6 col-lg-6 wow fadeInRight" { ...propForEffects }>
                            <div className="align-self-center">
                                <TitlePage title={ "Full-Service Electrical Contracting" } />
                                <p>
                                Being experienced in all aspects of electrical contracting, we are able to fulfill your needs, no matter how complex the project may be. From system design to trouble shooting, installation, maintenance budgets, emergency repair, and more, we are a reliable source for all electrical work.
                                </p>
                                <p>
                                Customers of TWS, enjoy prompt response, high-performance solutions, skilled workmanship and exceptional service. Our licensed and insured electricians are up-to-date with the latest technology and safety codes helping to safeguard your property and its occupants. Being a highly-responsive company with over 20 years of happy customers, help is only a phone call away. You can rely on us to minimize your outages, utility bills and maintenance expenses.
                                </p>
                            </div>
                        </div>
                    </div>
                    {/* // ------------------------------ROW----------------------------------- */}
                    <div className="row mb-4">
                        {/* // ------------------------------COL 1----------------------------------- */}
                        <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                            <div className="align-self-center">
                                <TitlePage title={ "Multi-Family Electrical Projects, Renovations & More" } />
                                <p>
                                At TWS, Inc., we provide prompt, efficient and quality services to cover all your needs. Having worked on hundreds of multi-family projects in New Jersey, Delaware and Pennsylvania over the years, we are able to provide you with cost-efficient and long-lasting solutions. Whether it is configuring a lighting and power control setup for a new construction, retrofitting energy-efficient lighting on an existing property or inspecting your security and fire alarm system to ensure it i up-to-code.
                                </p>
                            </div>
                        </div>
                        {/* // ------------------------------COL 2----------------------------------- */}
                        <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInRight" { ...propForEffects }>
                        <ImageRadius src="images/services/multifamily_2.jpg"/>
                            <div className="align-self-center">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* // ------------------------------ROW YELLOW SECTION----------------------------------- */}
            <div className="section-full box-about-list">
                    <div className="row spno">
                        {/* // ------------------------------COL 1----------------------------------- */}
                        <div className="col-lg-6 col-md-12 wow fadeInLeft" { ...propForEffects }>
                            <img src="images/services/multifamily_3.jpg" alt="" className="img-cover"/>
                        </div>
                        {/* // ------------------------------COL 2----------------------------------- */}
                        <div className="col-lg-6 col-md-12 bg-primary wow fadeInRight" { ...propForEffects }>
                        <SectionYellow { ...section } />
                        </div>
                    </div>
                </div>
                {/* // ------------------------------ROW DARK SECTION----------------------------------- */}
            <div className="section-full content-inner overlay-black-dark about-8-service bg-img-fix" style={{ backgroundImage: 'url(images/banner/commonBanner.jpg)', marginBottom: '0px' }}>
                    <div className="container">
                    <SectionDark { ...section2 } />
                    </div>
            </div>
        </div>
    )
}

export default Content;