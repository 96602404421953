import React from 'react'
import TitlePage from '../../../common/TitlePage'
import ListBox from '../../../common/ListBox'
import ImageRadius from '../../../common/ImageRadius'

export const Content = () => {
    let propForEffects = { 'data-wow-duration': '2s', 'data-wow-delay': '0.3s' }
    let list1 = [
        { name: 'Custom lighting solutions and retrofits.', },
        { name: 'Optimization of power systems for improving energy efficiency.' },
        { name: 'Programmable Logic Control centers for process automation.' },
        { name: 'Reduction and avoidance of fines for distorted electrical waveforms.'}
    ]
    return (
        <div className="container m-b60 customPage">
            <div className="section-full content-inner-1 about-bx">
                {/* // ------------------------------ROW----------------------------------- */}
                <div className="row mb-4">
                    {/* // ------------------------------COL 1----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                        <ImageRadius src="images/services/litghtin_retrofit_1.jpg"/>
                    </div>
                    {/* // ------------------------------COL 2----------------------------------- */}
                    <div className= "col-sm-12 col-md-6 col-lg-6 wow fadeInRight" { ...propForEffects }>
                        <div className="align-self-center">
                            <TitlePage title={ "Lighting Retrofit Solutions" } />
                            <p>
                                Our professional electricians provide energy efficient lighting retrofit solutions for all types of businesses. A retrofit involves modifications to your existing fixtures, lamps and systems. With new technologies that require less power than older lamps, retrofitting lights helps conserve energy. A lighting retrofit can produce significant savings on electricity costs.
                            </p>
                            <TitlePage title={ "Lighting Audits" } />
                            <p>
                                Our expert electricians perform comprehensive audits to evaluate the energy efficiency and power usage in your facility. We will send a skilled contractor to inspect your existing equipment, and create a retrofit plan that best suits your needs. Our customized solutions are designed to maximize your gains from retrofitting lights.
                            </p>
                        </div>
                    </div>
                </div>
                {/* // ------------------------------ROW----------------------------------- */}
                <div className="row mb-4">
                    {/* // ------------------------------COL 1----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                        <div className="align-self-center">
                            <TitlePage title={ "Retrofit Lights for Efficiency" } />
                            <p>
                                To retrofit lights, our electrical contractors replace all outdated lamps and ballasts with newer, more energy efficient products that require less power. Our contractors install fluorescent, compact fluorescent and LED bulbs, as well as a variety of other types.
                            </p>
                            <TitlePage title={ "Lighting Controls" } />
                            <p>
                                Hiring an electrician to install energy efficient lighting controls such as timers, dimmers and sensors to automate your illumination system prevents unnecessary power drains. Installing controls in industrial facilities dramatically lowers energy costs. Controllers in areas such as storage rooms, bathrooms, parking lots and walkways are practical solutions for any business.
                            </p>
                        </div>
                    </div>
                    {/* // ------------------------------COL 2----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInRight" { ...propForEffects }>
                    <ImageRadius src="images/services/litghtin_retrofit_2.jpg"/>
                        {/* <TitlePage title={ "List Title Example" } />
                        <ListBox list={ list1 }/> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Content;